import { createAction, props } from '@ngrx/store';
import { BasicResponse, ContactForm } from 'src/app/models';

export const sendMessage = createAction(
  '[MailClient] Send Message',
  props<{ contactForm: ContactForm }>()
);

export const sendMessageSuccess = createAction(
  '[MailClient] Send Message Success',
  props<{ response: BasicResponse }>()
);

export const sendMessageFailure = createAction(
  '[MailClient] Send Message Failure',
  props<{ response: BasicResponse }>()
);
