import { createSelector } from '@ngrx/store';
import * as reducers from '../reducers';

export const getMailClientState = createSelector(
  reducers.getAppState,
  (state: reducers.AppState) => state.mailClient
);

export const getIsSendingMessage = createSelector(
  getMailClientState,
  (state) => state.isSendingMessage
);
