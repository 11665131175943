import { PayPalService, SnackBarService } from 'src/app/services';
import * as PayPalActions from 'src/app/+state/actions/paypal.actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, switchMap } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable()
export class PayPalEffects {
  requestInvoice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PayPalActions.requestInvoice),
      switchMap((action) =>
        this.payPalService.requestInvoice(action.request).pipe(
          map(() => {
            action.callback?.();
            this.snackBarService.openSuccessSnackBar(
              'PayPal Invoice has been sent. Thank you.'
            );
            return PayPalActions.requestInvoiceSuccess();
          }),
          catchError((response) => {
            this.snackBarService.openFailureSnackBar(
              response.error?.message || 'Failed to issue PayPal Invoice'
            );
            return of(PayPalActions.requestInvoiceFailure());
          })
        )
      )
    )
  );

  sendInvoice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PayPalActions.sendInvoice),
      switchMap((action) =>
        this.payPalService.sendInvoice(action.bookingId).pipe(
          map(() => {
            action.callback?.();
            this.snackBarService.openSuccessSnackBar(
              'Invoice sent to customer'
            );
            return PayPalActions.sendInvoiceSuccess();
          }),
          catchError((response) => {
            this.snackBarService.openFailureSnackBar(
              response.error?.message || 'Failed to issue PayPal Invoice'
            );
            return of(PayPalActions.sendInvoiceFailure());
          })
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private payPalService: PayPalService,
    private snackBarService: SnackBarService
  ) {}
}
