<ng-container *ngIf="vm$ | async as vm">
  <mat-spinner *ngIf="vm.isLoading; else list"></mat-spinner>

  <ng-template #list>
    <table mat-table [dataSource]="vm.users ?? []">
      <ng-container matColumnDef="firstName">
        <th mat-header-cell *matHeaderCellDef>First Name</th>
        <td mat-cell *matCellDef="let user">{{ user.firstName }}</td>
      </ng-container>

      <ng-container matColumnDef="lastName">
        <th mat-header-cell *matHeaderCellDef>Last Name</th>
        <td mat-cell *matCellDef="let user">{{ user.lastName }}</td>
      </ng-container>

      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef>Email</th>
        <td mat-cell *matCellDef="let user">{{ user.email }}</td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let user">
          <swi-button
            *ngIf="
              user.isPhotographer &&
              ((user.id === vm.currentUserId &&
                vm.permissions?.manageOwnAvailability) ||
                (user.id !== vm.currentUserId &&
                  vm.permissions?.manageOthersAvailability))
            "
            (onClick)="editAvailability(user.id)"
            >Availability</swi-button
          >

          <swi-button (onClick)="editUser(user)">Edit</swi-button>
          <swi-button
            *ngIf="user.id !== vm.currentUserId"
            (onClick)="deleteUser(user)"
            >Delete</swi-button
          >
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </ng-template>
</ng-container>
