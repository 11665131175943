import { createAction, props } from '@ngrx/store';
import { BasicResponse, ChangePasswordRequest } from 'src/app/models';

export const changePassword = createAction(
  '[Account] Change Password',
  props<{ request: ChangePasswordRequest; callback?: () => void }>()
);

export const changePasswordSuccess = createAction(
  '[Account] Change Password Success',
  props<{ response: BasicResponse }>()
);

export const changePasswordFailure = createAction(
  '[Account] Change Password Failure',
  props<{ response: BasicResponse }>()
);

export const forgotPassword = createAction(
  '[Account] Forgot Password',
  props<{ email: string; callback?: () => void }>()
);

export const forgotPasswordSuccess = createAction(
  '[Account] Forgot Password Success',
  props<{ response: BasicResponse }>()
);

export const forgotPasswordFailure = createAction(
  '[Account] Forgot Password Failure',
  props<{ response: BasicResponse }>()
);
