<ng-container *ngIf="vm$ | async as vm">
  <swi-booking-filter></swi-booking-filter>

  <mat-spinner *ngIf="vm.isLoading; else list"></mat-spinner>

  <ng-template #list>
    <table mat-table [dataSource]="vm.bookings">
      <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef>Date</th>
        <td mat-cell *matCellDef="let booking">
          {{ booking.date | date : "longDate" }}
        </td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef>Status</th>
        <td mat-cell *matCellDef="let booking">
          {{ getBookingStatusText(booking.status) }}
        </td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>Name</th>
        <td mat-cell *matCellDef="let booking">{{ booking.name }}</td>
      </ng-container>

      <ng-container matColumnDef="phone">
        <th mat-header-cell *matHeaderCellDef>Phone</th>
        <td mat-cell *matCellDef="let booking">{{ booking.phone }}</td>
      </ng-container>

      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef>Email</th>
        <td mat-cell *matCellDef="let booking">{{ booking.email }}</td>
      </ng-container>

      <ng-container matColumnDef="photographers">
        <th mat-header-cell *matHeaderCellDef>Photographer(s)</th>
        <td mat-cell *matCellDef="let booking">
          {{ getPhotographerNames(booking.photographers) }}
        </td>
      </ng-container>

      <!-- <ng-container matColumnDef="notes">
        <th mat-header-cell *matHeaderCellDef>Notes</th>
        <td mat-cell *matCellDef="let booking">
          {{ booking.notes || "None" }}
        </td>
      </ng-container> -->

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let booking">
          <ng-container *ngIf="vm.permissions?.createInvoices">
            <a
              *ngIf="booking.invoiceUrl; else sendButton"
              [href]="booking.invoiceUrl"
              target="_blank"
              >View Invoice</a
            >
            <ng-template #sendButton>
              <swi-button
                [isProcessing]="vm.isRequestingInvoice"
                (onClick)="sendInvoice(booking.id)"
                >Send Invoice</swi-button
              >
            </ng-template>
          </ng-container>
          <swi-button (onClick)="editBooking(booking.id)">Edit</swi-button>
          <swi-button (onClick)="deleteBooking(booking)">Delete</swi-button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </ng-template>
</ng-container>
