import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FeatureFlags } from '../models';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagService {
  featureFlagUrl: string;

  constructor(private http: HttpClient) {
    this.featureFlagUrl = '/FeatureFlags';
  }

  getFeatureFlags(): Observable<FeatureFlags> {
    return this.http.get<FeatureFlags>(this.featureFlagUrl);
  }
}
