import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MAT_SLIDE_TOGGLE_DEFAULT_OPTIONS } from '@angular/material/slide-toggle';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { Effects } from './+state';
import { reducers } from './+state/reducers';
import { SwiAngularMaterialModule } from './angular-material.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BookingFilterComponent } from './components/booking-filter/booking-filter.component';
import { BookingFormComponent } from './components/booking-form/booking-form.component';
import { BookingOptionsModalComponent } from './components/booking-options-modal/booking-options-modal.component';
import { BookingsListComponent } from './components/bookings-list/bookings-list.component';
import { BookingsTabComponent } from './components/bookings-tab/bookings-tab.component';
import { ButtonComponent } from './components/button/button.component';
import { CalendarFilterComponent } from './components/calendar-filter/calendar-filter.component';
import { CalendarComponent } from './components/calendar/calendar.component';
import { CgPhotosInfoModalComponent } from './components/cg-photos-info-modal/cg-photos-info-modal.component';
import { ChangeMyPasswordFormComponent } from './components/change-my-password-form/change-my-password-form.component';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { ContactFormComponent } from './components/contact-form/contact-form.component';
import { ContactLinkComponent } from './components/contact-link/contact-link.component';
import { ContentWithImageComponent } from './components/content-with-image/content-with-image.component';
import { ContentWithImage2Component } from './components/content-with-image2/content-with-image2.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { LogoBannerComponent } from './components/logo-banner/logo-banner.component';
import { ManageAvailabilityComponent } from './components/manage-availability/manage-availability.component';
import { MyCalendarTabComponent } from './components/my-calendar-tab/my-calendar-tab.component';
import { NavigationComponent } from './components/navigation/navigation.component';
import { OverlappingContentComponent } from './components/overlapping-content/overlapping-content.component';
import { OverlappingContent2Component } from './components/overlapping-content2/overlapping-content2.component';
import { QuestionnaireComponent } from './components/questionnaire/questionnaire.component';
import { ScrollTopComponent } from './components/scroll-top/scroll-top.component';
import { UserFormComponent } from './components/user-form/user-form.component';
import { UsersListComponent } from './components/users-list/users-list.component';
import { UsersTabComponent } from './components/users-tab/users-tab.component';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { AboutPageComponent } from './pages/about-page/about-page.component';
import { AdminPageComponent } from './pages/admin-page/admin-page.component';
import { BookingPageComponent } from './pages/booking-page/booking-page.component';
import { ContactPageComponent } from './pages/contact-page/contact-page.component';
import { EditBookingPageComponent } from './pages/edit-booking-page/edit-booking-page.component';
import { GalleriesPageComponent } from './pages/galleries-page/galleries-page.component';
import { HomePageComponent } from './pages/home-page/home-page.component';
import { InvestmentPageComponent } from './pages/investment-page/investment-page.component';
import { LoginPageComponent } from './pages/login-page/login-page.component';

@NgModule({
  declarations: [
    AboutPageComponent,
    AdminPageComponent,
    AppComponent,
    BookingFilterComponent,
    BookingFormComponent,
    BookingOptionsModalComponent,
    BookingPageComponent,
    BookingsListComponent,
    BookingsTabComponent,
    ButtonComponent,
    CalendarComponent,
    CalendarFilterComponent,
    CgPhotosInfoModalComponent,
    ChangeMyPasswordFormComponent,
    ConfirmationDialogComponent,
    ContactFormComponent,
    ContactLinkComponent,
    ContentWithImageComponent,
    ContentWithImage2Component,
    ContactPageComponent,
    EditBookingPageComponent,
    FooterComponent,
    GalleriesPageComponent,
    HeaderComponent,
    HomePageComponent,
    InvestmentPageComponent,
    LoginPageComponent,
    LogoBannerComponent,
    ManageAvailabilityComponent,
    MyCalendarTabComponent,
    NavigationComponent,
    OverlappingContentComponent,
    OverlappingContent2Component,
    QuestionnaireComponent,
    ScrollTopComponent,
    UserFormComponent,
    UsersListComponent,
    UsersTabComponent,
  ],
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    StoreModule.forFeature('app', reducers),
    EffectsModule.forFeature(Effects),
    StoreModule.forRoot(),
    EffectsModule.forRoot(),
    SwiAngularMaterialModule,
    StoreRouterConnectingModule.forRoot(),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: 'outline' },
    },
    {
      provide: MAT_SLIDE_TOGGLE_DEFAULT_OPTIONS,
      useValue: { color: 'primary' },
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
