import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppState, getToken } from '../+state';
import { Store } from '@ngrx/store';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  token: string | null = null;

  constructor(appState: Store<AppState>) {
    appState.select(getToken).subscribe((token) => {
      this.token = token;
    });
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (this.token) {
      return next.handle(
        req.clone({
          setHeaders: {
            authorization: `Bearer ${this.token}`,
          },
        })
      );
    }
    return next.handle(req);
  }
}
