<div class="overlap-content-img">
  <div class="img-container">
    <img [src]="image" />
  </div>
  <div class="content-container">
    <div class="header">INVESTMENT</div>
    <div class="content">
      <h2>Families of 8 or less: $600</h2>
      <h2>Families of more than 8: $675</h2>
      <div class="list-container">
        Includes
        <ul>
          <li>45 minute session</li>
          <li>All images edited</li>
          <li>Digital access & download</li>
          <li>Full printing rights</li>
        </ul>
      </div>
    </div>
    <div>
      * $100 deposit secures the date, remainder is due the day of the session.
    </div>
  </div>
</div>
