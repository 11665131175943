import { AccountService, SnackBarService } from 'src/app/services';
import * as AccountActions from 'src/app/+state/actions/account.actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, switchMap } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable()
export class AccountEffects {
  changePassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountActions.changePassword),
      switchMap((action) =>
        this.accountService.changePassword(action.request).pipe(
          map((response) => {
            action.callback?.();
            this.snackBarService.openSuccessSnackBar('Password updated.');
            return AccountActions.changePasswordSuccess({ response });
          }),
          catchError((response) => {
            this.snackBarService.openFailureSnackBar(
              'Failed to update password.'
            );
            return of(AccountActions.changePasswordFailure({ response }));
          })
        )
      )
    )
  );

  forgotPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountActions.forgotPassword),
      switchMap((action) =>
        this.accountService.forgotPassword(action.email).pipe(
          map((response) => {
            action.callback?.();
            this.snackBarService.openFailureSnackBar(
              `Sent email to ${action.email} with password.`
            );
            return AccountActions.forgotPasswordSuccess({ response });
          }),
          catchError((response) =>
            of(AccountActions.forgotPasswordFailure({ response }))
          )
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private accountService: AccountService,
    private snackBarService: SnackBarService
  ) {}
}
