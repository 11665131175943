import { AvailabilityService, SnackBarService } from 'src/app/services';
import * as AvailabilityActions from 'src/app/+state/actions/availability.actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, switchMap } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable()
export class AvailabilityEffects {
  getAvailableDates$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AvailabilityActions.loadAvailableDates),
      switchMap((action) =>
        this.availabilityService.getAvailableDates(action.request).pipe(
          map((availableDates) =>
            AvailabilityActions.loadAvailableDatesSuccess({
              availableDates,
              loadedDate: action.request.date,
            })
          ),
          catchError((response) => {
            this.snackBarService.openFailureSnackBar(
              response.error?.message || 'Failed to load available dates.'
            );
            return of(
              AvailabilityActions.loadAvailableDatesFailure({ response })
            );
          })
        )
      )
    )
  );

  loadAvailability$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AvailabilityActions.loadAvailability),
      switchMap((action) =>
        this.availabilityService.getAvailability(action.userId).pipe(
          map((availabilityEntity) =>
            AvailabilityActions.loadAvailabilitySuccess({ availabilityEntity })
          ),
          catchError((response) => {
            this.snackBarService.openFailureSnackBar(
              response.error?.message || 'Failed to load user availability.'
            );
            return of(AvailabilityActions.loadAvailabilityFailure());
          })
        )
      )
    )
  );

  updateAvailableDates$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AvailabilityActions.updateAvailableDates),
      switchMap((action) =>
        this.availabilityService.updateAvailableDate(action.request).pipe(
          map((response) => {
            this.snackBarService.openSuccessSnackBar('Availability updated.');
            return AvailabilityActions.updateAvailableDatesSuccess({
              response,
            });
          }),
          catchError((response) => {
            this.snackBarService.openFailureSnackBar(
              response.error?.message || 'Failed to update available dates.'
            );
            return of(
              AvailabilityActions.updateAvailableDatesFailure({ response })
            );
          })
        )
      )
    )
  );

  updateAvailability$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AvailabilityActions.updateAvailability),
      switchMap((action) =>
        this.availabilityService
          .updateAvailability(action.availabilityEntity)
          .pipe(
            map((response) => {
              action.callback?.();
              this.snackBarService.openSuccessSnackBar('Availability updated.');
              return AvailabilityActions.updateAvailabilitySuccess({
                response,
              });
            }),
            catchError((response) => {
              this.snackBarService.openFailureSnackBar(
                response.error?.message || 'Failed to update user availability.'
              );
              return of(
                AvailabilityActions.updateAvailabilityFailure({ response })
              );
            })
          )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private availabilityService: AvailabilityService,
    private snackBarService: SnackBarService
  ) {}
}
