<section class="post-cards-container">
  <div *ngFor="let postCard of answers" class="post-card-with-image">
    <div class="questionnaire">
      <div class="header">
        <span>Q & A with {{ postCard.name }}</span>
        <span class="job-title">{{ postCard.jobTitle }}</span>
      </div>
      <div class="content">
        <div class="left-column">
          <div
            *ngFor="let ans of getLeftColumn(postCard.answers)"
            class="content-entry"
          >
            <div class="bold">{{ ans.question }}:</div>
            <div class="underlined-text">{{ ans.answer }}</div>
          </div>
        </div>
        <div class="right-column">
          <div
            *ngFor="let ans of getRightColumn(postCard.answers)"
            class="content-entry"
          >
            <div class="bold">{{ ans.question }}:</div>
            <div class="underlined-text">{{ ans.answer }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="image">
      <img [src]="postCard.headshot" />
    </div>
  </div>
</section>
