import { createSelector } from '@ngrx/store';
import * as reducers from '../reducers';

export const getSessionState = createSelector(
  reducers.getAppState,
  (state: reducers.AppState) => state.session
);

export const getIsLoggingIn = createSelector(
  getSessionState,
  (state) => state.isLoggingIn
);

export const getIsTokenInvalid = createSelector(
  getSessionState,
  (state) => state.tokenInvalid
);

export const getIsVerifyingToken = createSelector(
  getSessionState,
  (state) => state.isVerifyingToken
);

export const getToken = createSelector(getSessionState, (state) => state.token);
