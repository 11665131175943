import { SnackBarService, UsersService } from 'src/app/services';
import * as UsersActions from 'src/app/+state/actions/users.actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, from, map, mergeMap, of, switchMap } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable()
export class UsersEffects {
  createUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UsersActions.createUser),
      switchMap((action) =>
        this.usersService.createUser(action.user).pipe(
          map((response) => {
            action.callback(response);
            this.snackBarService.openSuccessSnackBar('User created.');
            return UsersActions.createUserSuccess({ response });
          }),
          catchError((response) => {
            this.snackBarService.openFailureSnackBar(
              response.error?.message || 'Failed to create user.'
            );
            return of(UsersActions.createUserFailure({ response }));
          })
        )
      )
    )
  );

  deleteUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UsersActions.deleteUser),
      switchMap((action) =>
        this.usersService.deleteUser(action.id).pipe(
          mergeMap((response) => {
            action.callback?.();
            this.snackBarService.openSuccessSnackBar('User deleted.');
            return from([
              UsersActions.deleteUserSuccess({ response }),
              UsersActions.loadAllUsers(),
            ]);
          }),
          catchError((response) => {
            this.snackBarService.openFailureSnackBar(
              response.error?.message || 'Failed to delete user.'
            );
            return of(UsersActions.deleteUserFailure({ response }));
          })
        )
      )
    )
  );

  editUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UsersActions.editUser),
      switchMap((action) =>
        this.usersService.editUser(action.user).pipe(
          mergeMap((response) => {
            action.callback(response);
            this.snackBarService.openSuccessSnackBar('User edited.');
            return from([
              UsersActions.editUserSuccess({ response }),
              UsersActions.loadAllUsers(),
            ]);
          }),
          catchError((response) => {
            this.snackBarService.openFailureSnackBar('Failed to edit user.');
            return of(UsersActions.editUserFailure({ response }));
          })
        )
      )
    )
  );

  loadAllUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UsersActions.loadAllUsers),
      switchMap(() =>
        this.usersService.getAllUsers().pipe(
          map((users) => UsersActions.loadAllUsersSuccess({ users })),
          catchError((response) => {
            this.snackBarService.openFailureSnackBar('Failed to load users.');
            return of(UsersActions.loadAllUsersFailure({ response }));
          })
        )
      )
    )
  );

  loadCurrentUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UsersActions.loadCurrentUser),
      switchMap(() =>
        this.usersService.getCurrentUser().pipe(
          map((user) => UsersActions.loadCurrentUserSuccess({ user })),
          catchError((response) => {
            this.snackBarService.openFailureSnackBar(
              'Failed to load current user.'
            );
            return of(UsersActions.loadCurrentUserFailure({ response }));
          })
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private snackBarService: SnackBarService,
    private usersService: UsersService
  ) {}
}
