import { createSelector } from '@ngrx/store';
import * as reducers from '../reducers';

export const getFeatureFlagsState = createSelector(
  reducers.getAppState,
  (state: reducers.AppState) => state.featureFlags
);

export const getFeatureFlags = createSelector(
  getFeatureFlagsState,
  (state) => state.featureFlags
);
