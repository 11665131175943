import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AboutPageComponent } from './pages/about-page/about-page.component';
import { AdminPageComponent } from './pages/admin-page/admin-page.component';
import { BookingPageComponent } from './pages/booking-page/booking-page.component';
import { ContactPageComponent } from './pages/contact-page/contact-page.component';
import { EditBookingPageComponent } from './pages/edit-booking-page/edit-booking-page.component';
import { GalleriesPageComponent } from './pages/galleries-page/galleries-page.component';
import { HomePageComponent } from './pages/home-page/home-page.component';
import { InvestmentPageComponent } from './pages/investment-page/investment-page.component';
import { LoginPageComponent } from './pages/login-page/login-page.component';
import { MyCalendarTabComponent } from './components/my-calendar-tab/my-calendar-tab.component';
import { UsersTabComponent } from './components/users-tab/users-tab.component';
import { BookingsTabComponent } from './components/bookings-tab/bookings-tab.component';

const routes: Routes = [
  {
    component: AdminPageComponent,
    path: 'admin',
    children: [
      {
        path: '',
        component: MyCalendarTabComponent,
      },
      {
        component: MyCalendarTabComponent,
        path: 'my-calendar',
      },
      {
        component: UsersTabComponent,
        path: 'users',
      },
      {
        component: BookingsTabComponent,
        path: 'bookings',
      },
    ],
  },
  {
    component: EditBookingPageComponent,
    path: 'admin/bookings/create',
  },
  {
    component: EditBookingPageComponent,
    path: 'admin/bookings/:id',
  },
  {
    component: AboutPageComponent,
    path: 'about',
  },
  {
    component: BookingPageComponent,
    path: 'booking',
  },
  {
    component: ContactPageComponent,
    path: 'contact',
  },
  {
    component: ContactPageComponent,
    path: 'contact/thankyou',
  },
  {
    component: GalleriesPageComponent,
    path: 'galleries',
  },
  {
    component: InvestmentPageComponent,
    path: 'investment',
  },
  {
    component: LoginPageComponent,
    path: 'login',
  },
  {
    component: HomePageComponent,
    path: '**',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
