<ng-container *ngIf="vm$ | async as vm">
  <!-- <h1 class="month-title">{{ getMonthName(month) }} - {{ year }}</h1> -->
  <section *ngIf="availableDates?.length" class="calendar-container">
    <div *ngFor="let weekDay of weekDays" class="week-day-label">
      {{ weekDay }}
    </div>
    <div *ngFor="let nullDay of offsetArray" class="day"></div>
    <div
      *ngFor="let day of availableDates; let i = index"
      class="day"
      [class.bookable]="day.available && !vm.loadAvailableDatesRequest?.userId"
      [class.is-available]="day.available"
      (click)="
        dayClicked(
          day.date,
          !!(day.available && !vm.loadAvailableDatesRequest?.userId)
        )
      "
    >
      {{ day.date }}
      <div
        *ngIf="
          day.availability === UserAvailability.Booked ||
          day.availability === UserAvailability.PendingBooked
        "
      >
        {{ getUserAvailabilityText(day.availability) }}
      </div>
      <div *ngIf="day.sunset" class="sunset">Sunset: {{ day.sunset }}</div>
      <mat-slide-toggle
        *ngIf="
          day &&
          vm.loadAvailableDatesRequest?.userId &&
          !isInThePast(day.date) &&
          (day.availability === UserAvailability.Available ||
            day.availability === UserAvailability.Off)
        "
        [(ngModel)]="availableDates[i].available"
        (change)="
          dateToggled(
            day.date,
            $event.checked,
            vm.loadAvailableDatesRequest?.userId
          )
        "
      ></mat-slide-toggle>
    </div>
  </section>
</ng-container>
