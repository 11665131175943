import { Booking } from '../models';

export enum BookingStatus {
  Terminated,
  Inquiry,
  DepositInvoiced,
  DepositPaid,
  PhotosTaken,
  PhotosSubmitted,
  PhotosBeingEdited,
  PhotosEdited,
  PhotosDelivered,
}

export function isBooked(booking: Booking): boolean {
  return booking.status >= BookingStatus.DepositPaid;
}

export function getBookingStatusText(status: BookingStatus): string {
  switch (status) {
    case BookingStatus.Terminated:
      return 'Terminated';
    case BookingStatus.Inquiry:
      return 'Inquiry';
    case BookingStatus.DepositInvoiced:
      return 'Deposit Invoiced';
    case BookingStatus.DepositPaid:
      return 'Deposit Paid';
    case BookingStatus.PhotosBeingEdited:
      return 'Photos Being Edited';
    case BookingStatus.PhotosDelivered:
      return 'Photos Delivered';
    case BookingStatus.PhotosEdited:
      return 'Photos Edited';
    case BookingStatus.PhotosSubmitted:
      return 'Photos Submitted';
    case BookingStatus.PhotosTaken:
      return 'Photos Taken';
    default:
      return 'Invalid Status';
  }
}

export function getBookingStatusDropdownOptions(): {
  text: string;
  value: BookingStatus;
}[] {
  let options = [];
  for (let i = 0; i <= BookingStatus.PhotosDelivered; i++) {
    options.push({ text: getBookingStatusText(i), value: i });
  }
  return options;
}
