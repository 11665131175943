import { animate, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'swi-overlapping-content',
  templateUrl: './overlapping-content.component.html',
  styleUrls: ['./overlapping-content.component.scss'],
  animations: [
    trigger('fade', [
      transition('void => *', [
        style({ opacity: 0 }),
        animate(1000, style({ opacity: 1 })),
      ]),
    ]),
  ],
})
export class OverlappingContentComponent implements OnChanges {
  @Input() images: string[] = [];
  activeImageSrc = '';
  nextImageSrc = '';
  showNextImage = false;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['images'].currentValue?.length > 1) {
      this.activeImageSrc = this.images[0];
      this.nextImageSrc = this.images[1];
      setInterval(() => this.toggleImage(), 5000);
    } else if (changes['images'].currentValue?.length === 1) {
      this.activeImageSrc = this.images[0];
    }
  }

  toggleImage(): void {
    this.showNextImage = true;

    setTimeout(() => {
      this.activeImageSrc = this.nextImageSrc;
      this.showNextImage = false;
      const nextIndex =
        this.images.findIndex((i) => i === this.nextImageSrc) + 1;
      if (nextIndex >= this.images.length) {
        this.nextImageSrc = this.images[0];
      } else {
        this.nextImageSrc = this.images[nextIndex];
      }
    }, 1200);
  }
}
