import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root',
})
export class SnackBarService {
  private config: MatSnackBarConfig = {
    duration: 10000,
    horizontalPosition: 'center',
    verticalPosition: 'top',
  };

  constructor(private snackBar: MatSnackBar) {}

  openFailureSnackBar(message: string): void {
    this.snackBar.open(message, undefined, this.config);
  }

  openSuccessSnackBar(message: string): void {
    this.snackBar.open(message, undefined, this.config);
  }
}
