import { Action, createReducer, on } from '@ngrx/store';
import * as AppSettingsActions from '../actions/app-settings.actions';
import { AppSettings } from 'src/app/models';

export interface State {
  appSettings: AppSettings | null;
  isLoadingAppSettings: boolean;
}

const initialState: State = {
  appSettings: null,
  isLoadingAppSettings: false,
};

const appSettingsReducer = createReducer(
  initialState,
  on(AppSettingsActions.loadAppSettings, (state) => ({
    ...state,
    isLoadingAppSettings: true,
  })),
  on(AppSettingsActions.loadAppSettingsSuccess, (state, { appSettings }) => ({
    ...state,
    appSettings,
    isLoadingAppSettings: false,
  })),
  on(AppSettingsActions.loadAppSettingsFailure, (state) => ({
    ...state,
    isLoadingAppSettings: false,
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return appSettingsReducer(state, action);
}
