import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CreateInvoiceRequest } from '../models';

@Injectable({
  providedIn: 'root',
})
export class PayPalService {
  payPalUrl: string;

  constructor(private http: HttpClient) {
    this.payPalUrl = '/PayPal';
  }

  // Customer request invoice
  requestInvoice(request: CreateInvoiceRequest): Observable<void> {
    const url = `${this.payPalUrl}/CreateInvoice`;
    return this.http.post<void>(url, request);
  }

  refreshToken(): Observable<void> {
    const url = `${this.payPalUrl}/RefreshToken`;
    return this.http.get<void>(url);
  }

  // Admin sends invoice to booking
  sendInvoice(bookingId: string): Observable<void> {
    const url = `${this.payPalUrl}/SendInvoice/${bookingId}`;
    return this.http.get<void>(url);
  }
}
