import { createSelector } from '@ngrx/store';
import * as reducers from '../reducers';

export const getAccountState = createSelector(
  reducers.getAppState,
  (state: reducers.AppState) => state.account
);

export const getIsChangingPassword = createSelector(
  getAccountState,
  (state) => state.isChangingPassword
);

export const getIsSendingForgotPasswordRequest = createSelector(
  getAccountState,
  (state) => state.isSendingForgotPasswordRequest
);

export const getPasswordSuccess = createSelector(
  getAccountState,
  (state) => state.success
);
