import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppSettings } from '../models';

@Injectable({
  providedIn: 'root',
})
export class AppSettingsService {
  url: string;

  constructor(private http: HttpClient) {
    this.url = '/AppSettings';
  }

  getAppSettings(): Observable<AppSettings> {
    return this.http.get<AppSettings>(this.url);
  }
}
