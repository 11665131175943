import { GoogleCalendarService, SnackBarService } from 'src/app/services';
import * as GoogleCalendarActions from 'src/app/+state/actions/google-calendar.actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, switchMap } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable()
export class GoogleCalendarEffects {
  loadConsentPageUrl$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GoogleCalendarActions.loadConsentPageUrl),
      switchMap(() =>
        this.googleCalendarService.loadConsentPageUrl().pipe(
          map((res) => {
            console.log(res.url);
            window.location.href = res.url;
            return GoogleCalendarActions.loadConsentPageUrlSuccess({
              url: res.url,
            });
          }),
          catchError((response) => {
            this.snackBarService.openFailureSnackBar(
              response.error?.message || 'Failed to sync calendar.'
            );
            return of(GoogleCalendarActions.loadConsentPageUrlFailure());
          })
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private googleCalendarService: GoogleCalendarService,
    private snackBarService: SnackBarService
  ) {}
}
