import { createAction, props } from '@ngrx/store';
import { CreateInvoiceRequest } from 'src/app/models';

export const requestInvoice = createAction(
  '[PayPal] Request Invoice',
  props<{ request: CreateInvoiceRequest; callback?: () => void }>()
);

export const requestInvoiceSuccess = createAction(
  '[PayPal] Request Invoice Success'
);

export const requestInvoiceFailure = createAction(
  '[PayPal] Request Invoice Failure'
);

export const sendInvoice = createAction(
  '[PayPal] Send Invoice',
  props<{ bookingId: string; callback?: () => void }>()
);

export const sendInvoiceSuccess = createAction('[PayPal] Send Invoice Success');

export const sendInvoiceFailure = createAction('[PayPal] Send Invoice Failure');
