import { Component, Inject, Input, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { combineLatest, map, tap } from 'rxjs';
import {
  AppState,
  createUser,
  editUser,
  getAllLocations,
  getIsSavingUser,
} from 'src/app/+state';
import { BasicResponse, User } from 'src/app/models';

@Component({
  selector: 'swi-user-form',
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.scss'],
})
export class UserFormComponent implements OnInit {
  userForm: FormGroup;

  isSavingUser$ = this.appState.select(getIsSavingUser);
  locations$ = this.appState.select(getAllLocations);

  vm$ = combineLatest([this.isSavingUser$, this.locations$]).pipe(
    map(([isSavingUser, locations]) => ({ isSavingUser, locations }))
  );

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: User,
    private appState: Store<AppState>,
    private dialogRef: MatDialogRef<UserFormComponent>,
    fb: FormBuilder
  ) {
    this.userForm = fb.group({
      email: ['', [Validators.required, Validators.email]],
      firstName: ['', Validators.required],
      id: null,
      isPhotographer: false,
      lastName: ['', Validators.required],
      locations: fb.group({
        destin: false,
        fortWalton: false,
        gulfShores: false,
        navarre: false,
        orangeBeach: false,
        panamaCity: false,
        pensacola: false,
      }),
      permissions: fb.group({
        createBookings: false,
        createInvoices: false,
        manageAllBookings: false,
        manageOwnBookings: false,
        manageOthersAvailability: false,
        manageOwnAvailability: false,
        manageUsers: false,
        overrideBookings: false,
      }),
    });
  }

  ngOnInit(): void {
    if (this.data) {
      this.userForm.patchValue(this.data);
    }
  }

  closeDialog(res?: BasicResponse): void {
    this.dialogRef.close(res);
  }

  handleResponse(res: BasicResponse): void {
    if (res?.success) {
      this.closeDialog(res);
    } else {
      console.error(res?.message);
    }
  }

  save(): void {
    const user = this.userForm.getRawValue() as User;
    if (!user.isPhotographer) {
      user.locations.destin = false;
      user.locations.fortWalton = false;
      user.locations.gulfShores = false;
      user.locations.navarre = false;
      user.locations.orangeBeach = false;
      user.locations.panamaCity = false;
      user.locations.pensacola = false;
    }
    const action = !!this.data ? editUser : createUser;
    this.appState.dispatch(
      action({
        user,
        callback: (res: BasicResponse) => this.handleResponse(res),
      })
    );
  }
}
