import { createAction, props } from '@ngrx/store';
import { BasicResponse, Location } from 'src/app/models';

export const loadAllLocations = createAction('[Locations] Load All Locations');

export const loadAllLocationsSuccess = createAction(
  '[Locations] Load All Locations Success',
  props<{ locations: Location[] }>()
);

export const loadAllLocationsFailure = createAction(
  '[Locations] Load All Locations Failure',
  props<{ response: BasicResponse }>()
);
