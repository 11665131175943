<mat-dialog-content>
  <div class="form-container">
    <form [formGroup]="userForm">
      <mat-form-field>
        <mat-label>First Name</mat-label>
        <input matInput formControlName="firstName" />
        <mat-error *ngIf="userForm.get('firstName')?.hasError('required')"
          >First Name is required.</mat-error
        >
      </mat-form-field>
      <mat-form-field>
        <mat-label>Last Name</mat-label>
        <input matInput formControlName="lastName" />
        <mat-error *ngIf="userForm.get('lastName')?.hasError('required')"
          >Last Name is required.</mat-error
        >
      </mat-form-field>
      <mat-form-field>
        <mat-label>Email</mat-label>
        <input matInput formControlName="email" />
        <mat-error *ngIf="userForm.get('email')?.hasError('required')"
          >Email is required.</mat-error
        >
        <mat-error *ngIf="userForm.get('email')?.hasError('email')"
          >Email is not valid.</mat-error
        >
      </mat-form-field>
      <mat-checkbox color="primary" formControlName="isPhotographer"
        >Photographer</mat-checkbox
      >
      <div class="checkbox-group" formGroupName="permissions">
        <h4>Permissions</h4>
        <mat-checkbox color="primary" formControlName="manageUsers"
          >Manage Users</mat-checkbox
        >
        <mat-checkbox color="primary" formControlName="manageOwnAvailability"
          >Manage Own Availability</mat-checkbox
        >
        <mat-checkbox color="primary" formControlName="manageOthersAvailability"
          >Manage Others Availability</mat-checkbox
        >
        <mat-checkbox color="primary" formControlName="createInvoices"
          >Create Invoices</mat-checkbox
        >
        <mat-checkbox color="primary" formControlName="createBookings"
          >Create Bookings</mat-checkbox
        >
        <mat-checkbox color="primary" formControlName="manageOwnBookings"
          >Manage Own Bookings</mat-checkbox
        >
        <mat-checkbox color="primary" formControlName="manageAllBookings"
          >Manage All Bookings</mat-checkbox
        >
        <mat-checkbox color="primary" formControlName="overrideBookings"
          >Override Bookings</mat-checkbox
        >
      </div>
      <div
        *ngIf="userForm.get('isPhotographer')?.value"
        class="checkbox-group"
        formGroupName="locations"
      >
        <h4>Locations</h4>
        <mat-checkbox color="primary" formControlName="destin"
          >Destin</mat-checkbox
        >
        <mat-checkbox color="primary" formControlName="fortWalton"
          >Fort Walton</mat-checkbox
        >
        <mat-checkbox color="primary" formControlName="gulfShores"
          >Gulf Shores</mat-checkbox
        >
        <mat-checkbox color="primary" formControlName="navarre"
          >Navarre</mat-checkbox
        >
        <mat-checkbox color="primary" formControlName="orangeBeach"
          >Orange Beach</mat-checkbox
        >
        <mat-checkbox color="primary" formControlName="panamaCity"
          >Panama City</mat-checkbox
        >
        <mat-checkbox color="primary" formControlName="pensacola"
          >Pensacola</mat-checkbox
        >
      </div>
      <div class="buttons-container">
        <swi-button
          (onClick)="save()"
          [disabled]="userForm.invalid"
          [isProcessing]="(isSavingUser$ | async) === true"
          >Submit</swi-button
        >
        <swi-button (onClick)="closeDialog()">Cancel</swi-button>
      </div>
    </form>
  </div>
</mat-dialog-content>
