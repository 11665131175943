import { Action, createReducer, on } from '@ngrx/store';
import { User } from 'src/app/models';
import * as UsersActions from '../actions/users.actions';

export interface State {
  currentUser: User | null;
  isDeletingUser: boolean;
  isLoadingCurrentUser: boolean;
  isLoadingUsers: boolean;
  isSavingUser: boolean;
  users: User[] | null;
}

const initialState: State = {
  currentUser: null,
  isDeletingUser: false,
  isLoadingCurrentUser: false,
  isLoadingUsers: false,
  isSavingUser: false,
  users: null,
};

const usersReducer = createReducer(
  initialState,
  on(UsersActions.createUser, (state) => ({
    ...state,
    isSavingUser: true,
  })),
  on(
    UsersActions.createUserSuccess,
    UsersActions.createUserFailure,
    (state) => ({
      ...state,
      isSavingUser: false,
    })
  ),
  on(UsersActions.deleteUser, (state) => ({
    ...state,
    isDeletingUser: true,
  })),
  on(
    UsersActions.deleteUserSuccess,
    UsersActions.deleteUserFailure,
    (state) => ({
      ...state,
      isDeletingUser: false,
    })
  ),
  on(UsersActions.editUser, (state) => ({
    ...state,
    isSavingUser: true,
  })),
  on(UsersActions.editUserSuccess, UsersActions.editUserFailure, (state) => ({
    ...state,
    isSavingUser: false,
  })),
  on(UsersActions.loadAllUsers, (state) => ({
    ...state,
    isLoadingUsers: true,
    users: null,
  })),
  on(UsersActions.loadAllUsersSuccess, (state, { users }) => ({
    ...state,
    isLoadingUsers: false,
    users,
  })),
  on(UsersActions.loadAllUsersFailure, (state) => ({
    ...state,
    isLoadingUsers: false,
  })),
  on(UsersActions.loadCurrentUser, (state) => ({
    ...state,
    isLoadingCurrentUser: true,
    currentUser: null,
  })),
  on(UsersActions.loadCurrentUserSuccess, (state, { user }) => ({
    ...state,
    isLoadingCurrentUser: false,
    currentUser: user,
  })),
  on(UsersActions.loadCurrentUserFailure, (state) => ({
    ...state,
    isLoadingCurrentUser: false,
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return usersReducer(state, action);
}
