import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { CookieService } from 'ngx-cookie-service';
import { catchError, map, of, switchMap } from 'rxjs';
import * as SessionActions from 'src/app/+state/actions/session.actions';
import { APP_COOKIE } from 'src/app/constants';
import { SessionService, SnackBarService } from 'src/app/services';

@Injectable()
export class SessionEffects {
  login$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SessionActions.login),
      switchMap((action) =>
        this.sessionService.login(action.loginForm).pipe(
          map((response) => {
            action.callback?.();
            this.cookie.set(APP_COOKIE, JSON.stringify(response), {
              expires: response.expiration,
            });
            return SessionActions.loginSuccess({ response });
          }),
          catchError((response) => {
            this.snackBarService.openFailureSnackBar(
              response.error?.message || 'Failed to login.'
            );
            return of(SessionActions.loginFailure({ response }));
          })
        )
      )
    )
  );

  logout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SessionActions.logout),
      switchMap((action) =>
        this.sessionService.logout().pipe(
          map((response) => {
            action.callback?.();
            return SessionActions.logoutSuccess({ response });
          }),
          catchError((response) => {
            this.snackBarService.openFailureSnackBar(
              response.error?.message || 'Failed to logout.'
            );
            return of(SessionActions.logoutFailure({ response }));
          })
        )
      )
    )
  );

  verifyToken$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SessionActions.verifyToken),
      switchMap((action) =>
        this.sessionService.verifyToken().pipe(
          map((response) => {
            action.callback?.();
            if (response) {
              return SessionActions.verifyTokenValid({ response });
            }
            return SessionActions.verifyTokenInvalid();
          }),
          catchError((response) => {
            this.snackBarService.openFailureSnackBar(
              response.error?.message || 'Failed to login.'
            );
            return of(SessionActions.verifyTokenInvalid());
          })
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private cookie: CookieService,
    private sessionService: SessionService,
    private snackBarService: SnackBarService
  ) {}
}
