import { Action, createReducer, on } from '@ngrx/store';
import * as PayPalActions from '../actions/paypal.actions';

export interface State {
  isRequestingInvoice: boolean;
}

const initialState: State = {
  isRequestingInvoice: false,
};

const payPalReducer = createReducer(
  initialState,
  on(PayPalActions.requestInvoice, PayPalActions.sendInvoice, (state) => ({
    ...state,
    isRequestingInvoice: true,
  })),
  on(
    PayPalActions.requestInvoiceSuccess,
    PayPalActions.requestInvoiceFailure,
    PayPalActions.sendInvoiceSuccess,
    PayPalActions.sendInvoiceFailure,
    (state) => ({
      ...state,
      isRequestingInvoice: false,
    })
  )
);

export function reducer(state: State | undefined, action: Action) {
  return payPalReducer(state, action);
}
