import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { combineLatest, map, tap } from 'rxjs';
import {
  AppState,
  loadConsentPageUrl,
  getCurrentUser,
  getCurrentUserPermissions,
  getIsLoggingIn,
  getIsTokenInvalid,
  getIsVerifyingToken,
  getToken,
  getUrl,
  loadCurrentUser,
  logout,
} from 'src/app/+state';
import { ChangeMyPasswordFormComponent } from 'src/app/components/change-my-password-form/change-my-password-form.component';
import { ManageAvailabilityComponent } from 'src/app/components/manage-availability/manage-availability.component';

@Component({
  selector: 'swi-admin-page',
  templateUrl: './admin-page.component.html',
  styleUrls: ['./admin-page.component.scss'],
})
export class AdminPageComponent {
  tabMyCalendarEnabled = false;
  tabUsersEnabled = false;
  tabBookingsEnabled = false;

  currentUser$ = this.appState.select(getCurrentUser);
  isLoggingIn$ = this.appState.select(getIsLoggingIn);
  isTokenInvalid$ = this.appState.select(getIsTokenInvalid);
  isVerifyingToken$ = this.appState.select(getIsVerifyingToken);
  permissions$ = this.appState.select(getCurrentUserPermissions);
  redirectOnInvalidToken$ = combineLatest([
    this.isLoggingIn$,
    this.isTokenInvalid$,
    this.isVerifyingToken$,
  ]).pipe(
    map(([isLoggingIn, isTokenInvalid, isVerifyingToken]) => {
      if (!isLoggingIn && isTokenInvalid && !isVerifyingToken) {
        this.router.navigateByUrl('/');
      }
    })
  );
  token$ = this.appState.select(getToken).pipe(
    tap((token) => {
      if (token) {
        this.appState.dispatch(loadCurrentUser());
      }
    })
  );
  destination$ = this.appState.select(getUrl).pipe(
    map((url) => {
      if (!url) return '';
      const urlParts = url.split('/');
      return urlParts[urlParts.length - 1];
    })
  );

  vm$ = combineLatest([
    this.currentUser$,
    this.destination$,
    this.permissions$,
    this.redirectOnInvalidToken$,
    this.token$,
  ]).pipe(
    map(([currentUser, destination, permissions, _redirect, token]) => {
      if (currentUser && permissions && destination) {
        this.tabMyCalendarEnabled = currentUser.isPhotographer;
        this.tabUsersEnabled = permissions.manageUsers;
        this.tabBookingsEnabled =
          permissions.createBookings ||
          permissions.manageAllBookings ||
          permissions.manageOwnBookings;

        if (
          destination === 'admin' ||
          (!this.tabMyCalendarEnabled && destination === 'my-calendar') ||
          (!this.tabUsersEnabled && destination === 'users') ||
          (!this.tabBookingsEnabled && destination === 'bookings')
        ) {
          this.redirectToFirstAvailableTab();
        }
      }

      return {
        currentUser,
        permissions,
        token,
      };
    })
  );

  constructor(
    private appState: Store<AppState>,
    private dialog: MatDialog,
    public router: Router
  ) {}

  logout(): void {
    this.appState.dispatch(
      logout({ callback: () => this.router.navigateByUrl('/') })
    );
  }

  openChangeMyPasswordModal(): void {
    this.dialog.open(ChangeMyPasswordFormComponent);
  }

  openManageAvailabilityModal(userId: string | undefined): void {
    const data = userId;
    this.dialog.open(ManageAvailabilityComponent, { data, autoFocus: false });
  }

  redirectToFirstAvailableTab(): void {
    let path = ['admin'];
    if (this.tabMyCalendarEnabled) {
      path.push('my-calendar');
    } else if (this.tabUsersEnabled) {
      path.push('users');
    } else if (this.tabBookingsEnabled) {
      path.push('bookings');
    } else {
      path = ['/'];
    }
    this.router.navigate(path);
  }

  syncToGoogleCalendar(): void {
    console.log('here1');
    this.appState.dispatch(loadConsentPageUrl());
  }
}
