<ng-container *ngIf="vm$ | async as vm">
  <section class="filters-container">
    <mat-form-field *ngIf="!userId">
      <mat-label>Location</mat-label>
      <mat-select
        [formControl]="location"
        (selectionChange)="locationSelected($event.value)"
      >
        <mat-select-trigger>
          {{ getLocationName(location.value || "") }}
        </mat-select-trigger>
        <mat-option *ngFor="let location of locationsList" [value]="location.id"
          ><strong>{{ location.name }}</strong> <br />
          ({{ location.spot }})</mat-option
        >
      </mat-select>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Month</mat-label>
      <mat-select
        [formControl]="month"
        (selectionChange)="monthSelected($event.value)"
      >
        <mat-select-trigger>
          {{ month.value?.text }}
        </mat-select-trigger>
        <mat-option
          *ngFor="let monthSelection of monthsList"
          [value]="monthSelection"
          >{{ monthSelection.text }}</mat-option
        >
      </mat-select>
    </mat-form-field>
  </section>
</ng-container>
