import { Action, createReducer, on } from '@ngrx/store';
import * as PhotographersActions from '../actions/photographers.actions';
import { SimpleUser, SimpleUserAvailability } from 'src/app/models';

export interface State {
  isLoadingPhotographers: boolean;
  photographers: SimpleUser[];
  photographersAvailable: SimpleUserAvailability[];
}

const initialState: State = {
  isLoadingPhotographers: false,
  photographers: [],
  photographersAvailable: [],
};

const photographersReducer = createReducer(
  initialState,
  on(PhotographersActions.clearPhotographers, () => initialState),
  on(
    PhotographersActions.loadPhotographers,
    PhotographersActions.loadPhotographersAvailable,
    (state) => ({
      ...state,
      isLoadingPhotographers: true,
    })
  ),
  on(
    PhotographersActions.loadPhotographersSuccess,
    (state, { photographers }) => ({
      ...state,
      isLoadingPhotographers: false,
      photographers,
    })
  ),
  on(
    PhotographersActions.loadPhotographersAvailableSuccess,
    (state, { photographers }) => ({
      ...state,
      isLoadingPhotographers: false,
      photographersAvailable: photographers,
    })
  ),
  on(
    PhotographersActions.loadPhotographersFailure,
    PhotographersActions.loadPhotographersAvailableFailure,
    (state) => ({
      ...state,
      isLoadingPhotographers: false,
    })
  )
);

export function reducer(state: State | undefined, action: Action) {
  return photographersReducer(state, action);
}
