import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';
import * as accountReducer from './account.reducer';
import * as appSettingsReducer from './app-settings.reducer';
import * as availabilityReducer from './availability.reducer';
import * as bookingsReducer from './bookings.reducer';
import * as featureFlagsReducer from './feature-flags.reducer';
import * as galleryReducer from './gallery.reducer';
import * as googleCalendarReducer from './google-calendar.reducer';
import * as locationsReducer from './locations.reducer';
import * as mailClientReducer from './mail-client.reducer';
import * as payPalReducer from './paypal.reducer';
import * as photographersReducer from './photographers.reducer';
import * as routerReducer from '@ngrx/router-store';
import * as sessionReducer from './session.reducer';
import * as usersReducer from './users.reducer';

export interface AppState {
  account: accountReducer.State;
  appSettings: appSettingsReducer.State;
  availability: availabilityReducer.State;
  bookings: bookingsReducer.State;
  featureFlags: featureFlagsReducer.State;
  gallery: galleryReducer.State;
  googleCalendar: googleCalendarReducer.State;
  locations: locationsReducer.State;
  mailClient: mailClientReducer.State;
  payPal: payPalReducer.State;
  photographers: photographersReducer.State;
  router: routerReducer.RouterReducerState;
  session: sessionReducer.State;
  users: usersReducer.State;
}

export const reducers: ActionReducerMap<AppState> = {
  account: accountReducer.reducer,
  appSettings: appSettingsReducer.reducer,
  availability: availabilityReducer.reducer,
  bookings: bookingsReducer.reducer,
  featureFlags: featureFlagsReducer.reducer,
  gallery: galleryReducer.reducer,
  googleCalendar: googleCalendarReducer.reducer,
  locations: locationsReducer.reducer,
  mailClient: mailClientReducer.reducer,
  payPal: payPalReducer.reducer,
  photographers: photographersReducer.reducer,
  router: routerReducer.routerReducer,
  session: sessionReducer.reducer,
  users: usersReducer.reducer,
};

export const getAppState = createFeatureSelector<AppState>('app');
