import { createAction, props } from '@ngrx/store';
import {
  AvailabilityEntity,
  AvailableDate,
  BasicResponse,
  LoadAvailableDatesRequest,
  UpdateAvailableDateRequest,
} from 'src/app/models';

export const clearAvailability = createAction(
  '[Availability] Clear Availability'
);

export const loadAvailableDates = createAction(
  '[Availability] Load Available Dates',
  props<{ request: LoadAvailableDatesRequest }>()
);

export const loadAvailableDatesSuccess = createAction(
  '[Availability] Load Available Dates Success',
  props<{
    availableDates: AvailableDate[];
    loadedDate: string;
  }>()
);

export const loadAvailableDatesFailure = createAction(
  '[Availability] Load Available Dates Failure',
  props<{ response: BasicResponse }>()
);

export const loadAvailability = createAction(
  '[Availability] Load Availability',
  props<{ userId: string }>()
);

export const loadAvailabilitySuccess = createAction(
  '[Availability] Load Availability Success',
  props<{ availabilityEntity: AvailabilityEntity }>()
);

export const loadAvailabilityFailure = createAction(
  '[Availability] Load Availability Failure'
);

export const updateAvailableDates = createAction(
  '[Availability] Update Available Dates',
  props<{ request: UpdateAvailableDateRequest }>()
);

export const updateAvailableDatesSuccess = createAction(
  '[Availability] Update Available Dates Success',
  props<{ response: BasicResponse }>()
);

export const updateAvailableDatesFailure = createAction(
  '[Availability] Update Available Dates Failure',
  props<{ response: BasicResponse }>()
);

export const updateAvailability = createAction(
  '[Availability] Update Availability',
  props<{ availabilityEntity: AvailabilityEntity; callback?: () => void }>()
);

export const updateAvailabilitySuccess = createAction(
  '[Availability] Update Availability Success',
  props<{ response: BasicResponse }>()
);

export const updateAvailabilityFailure = createAction(
  '[Availability] Update Availability Failure',
  props<{ response: BasicResponse }>()
);
