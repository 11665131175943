<section class="gallery-container">
  <div
    *ngFor="let image of images; let i = index"
    class="gallery-image"
    (click)="openGalleryAtIndex(i)"
  >
    <img [class.pointer]="!isSmallScreen" [src]="image" />
  </div>
</section>

<section *ngIf="overlayOpen" class="gallery-overlay"></section>

<div *ngIf="overlayOpen" class="focused-image" (click)="closeGalleryOverlay()">
  <div class="prev-next-container">
    <div class="prev-next" (click)="prevClicked(); $event.stopPropagation()">
      <
    </div>
  </div>
  <img [src]="focusedImage" (click)="$event.stopPropagation()" />
  <div class="prev-next-container">
    <div class="prev-next" (click)="nextClicked(); $event.stopPropagation()">
      >
    </div>
  </div>
</div>

<swi-contact-link></swi-contact-link>
