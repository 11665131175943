import { createAction, props } from '@ngrx/store';
import { BasicResponse, User } from 'src/app/models';

export const createUser = createAction(
  '[Users] Create User',
  props<{ user: User; callback: (response: BasicResponse) => void }>()
);

export const createUserSuccess = createAction(
  '[Users] Create User Success',
  props<{ response: BasicResponse }>()
);

export const createUserFailure = createAction(
  '[Users] Create User Failure',
  props<{ response: BasicResponse }>()
);

export const deleteUser = createAction(
  '[Users] Delete User',
  props<{ id: string; callback?: () => void }>()
);

export const deleteUserSuccess = createAction(
  '[Users] Delete User Success',
  props<{ response: BasicResponse }>()
);

export const deleteUserFailure = createAction(
  '[Users] Delete User Failure',
  props<{ response: BasicResponse }>()
);

export const editUser = createAction(
  '[Users] Edit User',
  props<{ user: User; callback: (response: BasicResponse) => void }>()
);

export const editUserSuccess = createAction(
  '[Users] Edit User Success',
  props<{ response: BasicResponse }>()
);

export const editUserFailure = createAction(
  '[Users] Edit User Failure',
  props<{ response: BasicResponse }>()
);

export const loadAllUsers = createAction('[Users] Load All Users');

export const loadAllUsersSuccess = createAction(
  '[Users] Load All Users Success',
  props<{ users: User[] }>()
);

export const loadAllUsersFailure = createAction(
  '[Users] Load All Users Failure',
  props<{ response: BasicResponse }>()
);

export const loadCurrentUser = createAction('[Users] Load Current User');

export const loadCurrentUserSuccess = createAction(
  '[Users] Load Current User Success',
  props<{ user: User }>()
);

export const loadCurrentUserFailure = createAction(
  '[Users] Load Current User Failure',
  props<{ response: BasicResponse }>()
);
