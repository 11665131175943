import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BasicResponse, User } from '../models';

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  usersUrl: string;

  constructor(private http: HttpClient) {
    this.usersUrl = '/Users';
  }

  createUser(user: User): Observable<BasicResponse> {
    return this.http.post<BasicResponse>(this.usersUrl, user);
  }

  deleteUser(id: string): Observable<BasicResponse> {
    return this.http.delete<BasicResponse>(`${this.usersUrl}/${id}`);
  }

  editUser(user: User): Observable<BasicResponse> {
    return this.http.put<BasicResponse>(this.usersUrl, user);
  }

  getAllUsers(): Observable<User[]> {
    return this.http.get<User[]>(this.usersUrl);
  }

  getCurrentUser(): Observable<User> {
    return this.http.get<User>(`${this.usersUrl}/Current`);
  }
}
