import { Action, createReducer, on } from '@ngrx/store';
import * as AccountActions from '../actions/account.actions';

export interface State {
  isChangingPassword: boolean;
  isSendingForgotPasswordRequest: boolean;
  success: boolean | null;
}

const initialState: State = {
  isChangingPassword: false,
  isSendingForgotPasswordRequest: false,
  success: null,
};

const accountReducer = createReducer(
  initialState,
  on(AccountActions.changePassword, (state) => ({
    ...state,
    isChangingPassword: true,
    success: null,
  })),
  on(
    AccountActions.changePasswordSuccess,
    AccountActions.changePasswordFailure,
    (state, { response }) => ({
      ...state,
      isChangingPassword: false,
      success: response.success,
    })
  ),
  on(AccountActions.forgotPassword, (state) => ({
    ...state,
    isSendingForgotPasswordRequest: true,
    success: null,
  })),
  on(
    AccountActions.forgotPasswordSuccess,
    AccountActions.forgotPasswordFailure,
    (state, { response }) => ({
      ...state,
      isSendingForgotPasswordRequest: false,
      success: response.success,
    })
  )
);

export function reducer(state: State | undefined, action: Action) {
  return accountReducer(state, action);
}
