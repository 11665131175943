import { createSelector } from '@ngrx/store';
import * as reducers from '../reducers';

export const getUsersState = createSelector(
  reducers.getAppState,
  (state: reducers.AppState) => state.users
);

export const getCurrentUser = createSelector(
  getUsersState,
  (state) => state.currentUser
);

export const getCurrentUserId = createSelector(
  getUsersState,
  (state) => state.currentUser?.id
);

export const getCurrentUserPermissions = createSelector(
  getUsersState,
  (state) => state.currentUser?.permissions
);

export const getIsDeletingUser = createSelector(
  getUsersState,
  (state) => state.isDeletingUser
);

export const getIsSavingUser = createSelector(
  getUsersState,
  (state) => state.isSavingUser
);

export const getIsLoadingCurrentUser = createSelector(
  getUsersState,
  (state) => state.isLoadingCurrentUser
);

export const getIsLoadingAllUsers = createSelector(
  getUsersState,
  (state) => state.isLoadingUsers
);

export const getAllUsers = createSelector(
  getUsersState,
  (state) => state.users
);
