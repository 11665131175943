import { AppSettingsService, SnackBarService } from 'src/app/services';
import * as AppSettingsActions from 'src/app/+state/actions/app-settings.actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, switchMap } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable()
export class AppSettingsEffects {
  loadAppSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppSettingsActions.loadAppSettings),
      switchMap(() =>
        this.appSettingsService.getAppSettings().pipe(
          map((appSettings) =>
            AppSettingsActions.loadAppSettingsSuccess({ appSettings })
          ),
          catchError((response) => {
            this.snackBarService.openFailureSnackBar(
              'Failed to load application settings.'
            );
            return of(AppSettingsActions.loadAppSettingsFailure({ response }));
          })
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private appSettingsService: AppSettingsService,
    private snackBarService: SnackBarService
  ) {}
}
