import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { combineLatest, map, tap } from 'rxjs';
import {
  AppState,
  clearPhotographersAvailable,
  createBooking,
  getAllLocations,
  getBooking,
  getCurrentUserPermissions,
  getPhotographersAvailable,
  loadBooking,
  loadPhotographersAvailable,
  updateBooking,
} from 'src/app/+state';
import { PHONE_PATTERN } from 'src/app/constants';
import {
  BookingStatus,
  getBookingStatusDropdownOptions,
  getBookingStatusText,
  getUserAvailabilityText,
} from 'src/app/enums';
import { Booking, Location, SimpleUser } from 'src/app/models';

@Component({
  selector: 'swi-booking-form',
  templateUrl: './booking-form.component.html',
  styleUrls: ['./booking-form.component.scss'],
})
export class BookingFormComponent implements OnDestroy, OnInit {
  bookingForm: FormGroup;
  bookingStatusOptions = getBookingStatusDropdownOptions();
  getBookingStatusText = getBookingStatusText;
  getUserAvailabilityText = getUserAvailabilityText;
  isEditing = false;

  booking$ = this.appState.select(getBooking).pipe(
    tap((booking) => {
      if (booking) {
        this.bookingForm.patchValue(booking);
        if (booking.status > BookingStatus.Inquiry) {
          this.bookingForm.get('date')?.addValidators(Validators.required);
        }
      }
    })
  );
  locations$ = this.appState.select(getAllLocations);
  permissions$ = this.appState.select(getCurrentUserPermissions);
  photographers$ = this.appState.select(getPhotographersAvailable);

  vm$ = combineLatest([
    this.booking$,
    this.locations$,
    this.permissions$,
    this.photographers$,
  ]).pipe(
    map(([_booking, locations, permissions, photographers]) => ({
      locations,
      permissions,
      photographers,
    }))
  );

  constructor(
    private appState: Store<AppState>,
    fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.bookingForm = fb.group({
      id: null,
      name: ['', Validators.required],
      email: ['', Validators.email],
      phone: ['', Validators.pattern(PHONE_PATTERN)],
      date: '',
      location: '',
      photographers: [],
      notes: '',
      status: [BookingStatus.Inquiry, Validators.required],
    });
  }

  ngOnDestroy(): void {
    this.appState.dispatch(clearPhotographersAvailable());
  }

  ngOnInit(): void {
    const bookingId = this.route.snapshot.params['id'];
    if (bookingId) {
      this.isEditing = true;
      this.appState.dispatch(loadBooking({ bookingId }));
    }
  }

  cancel(): void {
    this.router.navigate(['admin', 'bookings']);
  }

  getLocationName(id: string, locations: Location[]): string {
    return locations.find((l) => l.id === id)?.name || '';
  }

  getPhotographersName(id: string, photographers: SimpleUser[]): string {
    const photographer = photographers.find((p) => p.id === id);
    return photographer ? photographer.firstName : '';
  }

  loadAvailablePhotographers(): void {
    const date = this.bookingForm.get('date')?.value;
    const location = this.bookingForm.get('location')?.value;
    if (date && location) {
      this.appState.dispatch(loadPhotographersAvailable({ date, location }));
    }
  }

  save(): void {
    const action = this.isEditing ? updateBooking : createBooking;
    const booking: Booking = {
      ...this.bookingForm.getRawValue(),
    };
    const callback = () => this.router.navigate(['admin', 'bookings']);
    this.appState.dispatch(action({ booking, callback }));
  }

  statusChanged(status: BookingStatus): void {
    if (status > BookingStatus.Inquiry) {
      this.bookingForm.get('date')?.addValidators(Validators.required);
      this.bookingForm.get('location')?.addValidators(Validators.required);
      this.bookingForm.get('photographers')?.addValidators(Validators.required);
    } else {
      this.bookingForm.get('date')?.clearValidators();
      this.bookingForm.get('location')?.clearValidators();
      this.bookingForm.get('photographers')?.clearValidators();
    }
    this.bookingForm.get('date')?.updateValueAndValidity();
    this.bookingForm.get('location')?.updateValueAndValidity();
    this.bookingForm.get('photographers')?.updateValueAndValidity();
  }
}
