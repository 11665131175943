import { Component } from '@angular/core';

interface Questions {
  name: string;
  jobTitle: string;
  answers: QAndA[];
  headshot: string;
  // 'Most likely to binge watch': string;
  // 'My favorite board game': string;
  // 'My ideal vacation spot': string;
  // 'On a 10 hour flight I would like to sit next to': string;
  // 'Most used app on your phone': string;
  // 'My favorite candy': string;
  // 'Where were you born': string;
}

interface QAndA {
  question: string;
  answer: string;
}

@Component({
  selector: 'swi-questionnaire',
  templateUrl: './questionnaire.component.html',
  styleUrls: ['./questionnaire.component.scss'],
})
export class QuestionnaireComponent {
  answers: Questions[] = [
    {
      name: 'Christina',
      jobTitle: 'Owner/Lead Photographer',
      headshot: './assets/images/team-member-christina.jpg',
      answers: [
        { question: 'Most likely to binge watch', answer: 'Comedy Drama' },
        { question: 'My favorite board game', answer: 'Sorry' },
        {
          question: 'My ideal vacation spot',
          answer: 'Caribbean',
        },
        {
          question: 'On a 10 hour flight I would like to sit next to',
          answer: 'Amy Schumer',
        },
        { question: 'Most used app on your phone', answer: 'TikTok' },
        { question: 'My favorite candy', answer: 'Fruity/Chewy anything' },
        { question: 'Where were you born', answer: 'Cincinnati' },
      ],
    },
    {
      name: 'Deeanna',
      jobTitle: 'Photographer',
      headshot: './assets/images/team-member-deeanna.jpg',
      answers: [
        { question: 'Most likely to binge watch', answer: 'Sci-Fi' },
        { question: 'My favorite board game', answer: 'Life / Battleship' },
        {
          question: 'My ideal vacation spot',
          answer: 'Ancient ruins',
        },
        {
          question: 'On a 10 hour flight I would like to sit next to',
          answer: 'My daughter',
        },
        { question: 'Most used app on your phone', answer: 'Spotify' },
        { question: 'My favorite candy', answer: 'Chocolate coconut anything' },
        { question: 'Where were you born', answer: 'Glendale, Arizona' },
      ],
    },
    {
      name: 'Sara',
      jobTitle: 'Photographer',
      headshot: './assets/images/team-member-sara.jpg',
      answers: [
        { question: 'Most likely to binge watch', answer: 'Crime shows' },
        { question: 'My favorite board game', answer: 'Clue / Sorry' },
        {
          question: 'My ideal vacation spot',
          answer: 'Would love to go to Italy',
        },
        {
          question: 'On a 10 hour flight I would like to sit next to',
          answer: 'Ryan Reynolds',
        },
        { question: 'Most used app on your phone', answer: 'Facebook' },
        { question: 'My favorite candy', answer: 'Trolli sour worms' },
        { question: 'Where were you born', answer: 'Howell, Michigan' },
      ],
    },
    // {
    //   name: 'Rick',
    //   jobTitle: 'Photographer',
    //   headshot: '',
    //   answers: [
    //     { question: 'Most likely to binge watch', answer: 'Sopranos or Rome' },
    //     { question: 'My favorite board game', answer: 'Clue / Monopoly' },
    //     { question: 'My ideal vacation spot', answer: 'Europe' },
    //     {
    //       question: 'On a 10 hour flight I would like to sit next to',
    //       answer: 'Salvador Dali',
    //     },
    //     { question: 'Most used app on your phone', answer: 'Instagram' },
    //     { question: 'My favorite candy', answer: 'Snow Caps' },
    //     { question: 'Where were you born', answer: 'Faro, Portugal' },
    //   ],
    // },
  ];

  getLeftColumn(answers: QAndA[]): QAndA[] {
    return answers.filter((a, i) => i % 2 === 0);
  }

  getRightColumn(answers: QAndA[]): QAndA[] {
    return answers.filter((a, i) => i % 2 === 1);
  }
}
