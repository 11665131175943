import { Component } from '@angular/core';

@Component({
  selector: 'swi-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss'],
})
export class HomePageComponent {
  topAreaImages = [
    './assets/images/carousel1.jpg',
    './assets/images/carousel2.jpg',
    './assets/images/carousel3.jpg',
  ];
}
