import { MailClientService, SnackBarService } from 'src/app/services';
import * as MailClientActions from 'src/app/+state/actions/mail-client.actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, switchMap } from 'rxjs';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable()
export class MailClientEffects {
  sendMessage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MailClientActions.sendMessage),
      switchMap((action) =>
        this.mailClientService.sendMessage(action.contactForm).pipe(
          map((response) => {
            this.snackBarService.openSuccessSnackBar(
              'Message sent successfully. We will contact you soon.'
            );
            this.router.navigate(['contact', 'thankyou']);
            return MailClientActions.sendMessageSuccess({ response });
          }),
          catchError((response) => {
            this.snackBarService.openFailureSnackBar(
              'Failed to send message. Please try again later or call us for more information.'
            );
            return of(MailClientActions.sendMessageFailure({ response }));
          })
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private mailClientService: MailClientService,
    private router: Router,
    private snackBarService: SnackBarService
  ) {}
}
