import { Component } from '@angular/core';

@Component({
  selector: 'swi-investment-page',
  templateUrl: './investment-page.component.html',
  styleUrls: ['./investment-page.component.scss']
})
export class InvestmentPageComponent {

}
