<mat-dialog-content *ngIf="vm$ | async as vm">
  <div>
    <a>Send inquiry about {{ data.date | date : "longDate" }}</a>
  </div>
  <div>
    To secure {{ data.date | date : "longDate" }} as your date we only require a
    $50 deposit.
  </div>
  <div *ngIf="!showInvoiceRequestForm; else requestForm">
    <a (click)="showInvoiceRequestForm = true">Receive PayPal Invoice</a>
  </div>
  <ng-template #requestForm>
    <div class="form-container">
      <form [formGroup]="invoiceRequestForm">
        <mat-form-field>
          <mat-label>Name</mat-label>
          <input matInput formControlName="name" />
          <mat-error
            *ngIf="invoiceRequestForm.get('name')?.hasError('required')"
            >Name is required.</mat-error
          >
        </mat-form-field>
        <mat-form-field>
          <mat-label>Email</mat-label>
          <input matInput formControlName="email" />
          <mat-error
            *ngIf="invoiceRequestForm.get('email')?.hasError('required')"
            >Email is required.</mat-error
          >
          <mat-error *ngIf="invoiceRequestForm.get('email')?.hasError('email')"
            >Email is not valid.</mat-error
          >
        </mat-form-field>
        <mat-form-field>
          <mat-label>Phone</mat-label>
          <input matInput formControlName="phone" />
          <mat-error
            *ngIf="invoiceRequestForm.get('phone')?.hasError('pattern')"
            >Phone number is not valid.</mat-error
          >
        </mat-form-field>
      </form>
    </div>
  </ng-template>
  <div class="buttons-container">
    <swi-button
      *ngIf="showInvoiceRequestForm"
      (click)="payPalCall()"
      [isProcessing]="vm.isRequestingInvoice"
      >Submit Request</swi-button
    >
    <swi-button (click)="dialogRef.close()">Cancel</swi-button>
  </div>
</mat-dialog-content>
