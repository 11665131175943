import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Store } from '@ngrx/store';
import { combineLatest, map, tap } from 'rxjs';
import {
  AppState,
  getAllLocations,
  getPhotographers,
  loadBookings,
  loadPhotographers,
} from 'src/app/+state';
import {
  getBookingStatusDropdownOptions,
  getBookingStatusText,
} from 'src/app/enums';
import { LoadBookingsParams, Location, SimpleUser } from 'src/app/models';
import { getFullName } from 'src/app/utils';

@Component({
  selector: 'swi-booking-filter',
  templateUrl: './booking-filter.component.html',
  styleUrls: ['./booking-filter.component.scss'],
})
export class BookingFilterComponent implements OnInit {
  _dateOptionSelected = 0;
  get dateOptionSelected(): number {
    return this._dateOptionSelected;
  }
  set dateOptionSelected(value: number) {
    this._dateOptionSelected = value;
    this.loadBookings();
  }
  dateOptions = [
    { value: 0, text: 'Current/Future' },
    { value: 1, text: 'Past' },
  ];
  getFullName = getFullName;
  locationsList: Location[] = [];
  locations = new FormControl([]);
  photographerList: SimpleUser[] = [];
  photographers = new FormControl([]);
  statusesList = getBookingStatusDropdownOptions();
  statuses = new FormControl([]);
  today: Date;

  locations$ = this.appState.select(getAllLocations).pipe(
    tap((l) => {
      if (l) {
        this.locationsList = l;
      }
    })
  );

  photographers$ = this.appState.select(getPhotographers).pipe(
    tap((p) => {
      if (p) {
        this.photographerList = p;
      }
    })
  );

  vm$ = combineLatest([this.locations$, this.photographers$]).pipe(
    map(([locations, photographers]) => ({ locations, photographers }))
  );

  constructor(private appState: Store<AppState>) {
    this.today = new Date();
    this.today.setHours(0, 0, 0, 0);
  }

  ngOnInit(): void {
    this.loadPhotographers();
    this.loadBookings();
  }

  getBookingStatusSelectText(): string {
    if (!this.statuses?.value) return '';
    const numSelected = this.statuses.value.length;
    if (numSelected === this.statusesList.length) return 'All';
    const statusName = getBookingStatusText(this.statuses.value[0]);
    if (numSelected === 1) {
      return statusName;
    }
    return (
      statusName +
      '+' +
      (numSelected - 1) +
      'other' +
      (numSelected > 2 ? 's' : '')
    );
  }

  getDatesSelectText(): string {
    return (
      this.dateOptions.find((o) => o.value === this.dateOptionSelected)?.text ||
      ''
    );
  }

  getLocationName(id: string): string {
    return this.locationsList.find((l) => l.id === id)?.name || '';
  }

  getLocationsSelectText(): string {
    if (!this.locations?.value) return '';
    const numSelected = this.locations.value.length;
    if (numSelected === this.locationsList.length) return 'All';
    const locationName = this.getLocationName(this.locations.value[0]);
    if (numSelected === 1) {
      return locationName;
    }
    return (
      locationName +
      '+' +
      (numSelected - 1) +
      'other' +
      (numSelected > 2 ? 's' : '')
    );
  }

  getPhotographerName(id: string): string {
    const photographer = this.photographerList.find((p) => p.id === id);
    return getFullName(photographer);
  }

  getPhotographersSelectText(): string {
    if (!this.photographers?.value) return '';
    const numSelected = this.photographers.value.length;
    if (numSelected === this.photographerList.length) return 'All';
    const photographersName = this.getPhotographerName(
      this.photographers.value[0]
    );
    if (numSelected === 1) {
      return photographersName;
    }
    return (
      photographersName +
      '+' +
      (numSelected - 1) +
      'other' +
      (numSelected > 2 ? 's' : '')
    );
  }

  loadBookings(): void {
    let loadBookingsParams: LoadBookingsParams = {};
    if (this.dateOptionSelected === 0) {
      loadBookingsParams.dates = {
        start: this.today.toISOString(),
      };
    } else if (this.dateOptionSelected === 1) {
      loadBookingsParams.dates = {
        end: this.today.toISOString(),
      };
    }
    if (this.photographers.value?.length) {
      loadBookingsParams.photographers = this.photographers.value;
    }
    if (this.locations.value?.length) {
      loadBookingsParams.locations = this.locations.value;
    }
    if (this.statuses.value?.length) {
      loadBookingsParams.statuses = this.statuses.value;
    }
    this.appState.dispatch(loadBookings({ loadBookingsParams }));
  }

  loadPhotographers(): void {
    this.appState.dispatch(loadPhotographers());
  }

  setDateOption(value: number): void {
    this.dateOptionSelected = value;
    this.loadBookings();
  }
}
