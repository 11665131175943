import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { User } from 'src/app/models';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { ConfirmationDialogData } from '../confirmation-dialog/confirmation-dialog-data.model';
import { UserFormComponent } from '../user-form/user-form.component';
import { Store } from '@ngrx/store';
import {
  AppState,
  deleteUser,
  getAllUsers,
  getCurrentUserId,
  getCurrentUserPermissions,
  getIsLoadingAllUsers,
  loadAllUsers,
} from 'src/app/+state';
import { combineLatest, map } from 'rxjs';
import { ManageAvailabilityComponent } from '../manage-availability/manage-availability.component';
import { getFullName } from 'src/app/utils';

@Component({
  selector: 'swi-users-list',
  templateUrl: './users-list.component.html',
  styleUrls: ['./users-list.component.scss'],
})
export class UsersListComponent implements OnInit {
  displayedColumns: string[] = ['firstName', 'lastName', 'email', 'actions'];

  currentUserId$ = this.appState.select(getCurrentUserId);
  isLoading$ = this.appState.select(getIsLoadingAllUsers);
  permissions$ = this.appState.select(getCurrentUserPermissions);
  users$ = this.appState.select(getAllUsers);

  vm$ = combineLatest([
    this.currentUserId$,
    this.isLoading$,
    this.permissions$,
    this.users$,
  ]).pipe(
    map(([currentUserId, isLoading, permissions, users]) => ({
      currentUserId,
      isLoading,
      permissions,
      users,
    }))
  );

  constructor(private appState: Store<AppState>, private dialog: MatDialog) {}

  ngOnInit(): void {
    this.loadUsers();
  }

  deleteUser(user: User): void {
    const data: ConfirmationDialogData = {
      buttonText: 'Delete',
      message: `Are you sure you want to delete the user ${getFullName(
        user
      )}'?`,
    };
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, { data });

    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        this.appState.dispatch(deleteUser({ id: user.id }));
      }
    });
  }

  editAvailability(userId: string): void {
    const data = userId;
    this.dialog.open(ManageAvailabilityComponent, { data, autoFocus: false });
  }

  editUser(user: User): void {
    this.dialog.open(UserFormComponent, { data: user });
  }

  loadUsers(): void {
    this.appState.dispatch(loadAllUsers());
  }
}
