import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BasicResponse, Booking, LoadBookingsParams } from '../models';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BookingService {
  bookingUrl: string;

  constructor(private http: HttpClient) {
    this.bookingUrl = '/Bookings';
  }

  createBooking(booking: Booking): Observable<BasicResponse> {
    return this.http.post<BasicResponse>(this.bookingUrl, booking);
  }

  deleteBooking(id: string): Observable<BasicResponse> {
    const url = `${this.bookingUrl}/${id}`;
    return this.http.delete<BasicResponse>(url);
  }

  getBooking(bookingId: string): Observable<Booking> {
    const url = `${this.bookingUrl}/${bookingId}`;
    return this.http.get<Booking>(url);
  }

  getBookings(loadBookingsParams?: LoadBookingsParams): Observable<Booking[]> {
    let params = new HttpParams({});
    if (loadBookingsParams?.photographers?.length) {
      params = params.append(
        'photographers',
        loadBookingsParams.photographers.join(',')
      );
    }
    if (loadBookingsParams?.locations?.length) {
      params = params.append(
        'locations',
        loadBookingsParams.locations.join(',')
      );
    }
    if (loadBookingsParams?.statuses?.length) {
      params = params.append('statuses', loadBookingsParams.statuses.join(','));
    }
    if (loadBookingsParams?.dates?.start) {
      params = params.append('dateStart', loadBookingsParams.dates.start);
    }
    if (loadBookingsParams?.dates?.end) {
      params = params.append('dateEnd', loadBookingsParams.dates.end);
    }
    return this.http.get<Booking[]>(this.bookingUrl, { params });
  }

  updateBooking(booking: Booking): Observable<BasicResponse> {
    return this.http.put<BasicResponse>(this.bookingUrl, booking);
  }
}
