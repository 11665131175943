<ng-container *ngIf="vm$ | async as vm">
  <ng-container *ngIf="!!vm.token; else awaitingToken">
    <swi-button (onClick)="openChangeMyPasswordModal()"
      >Change My Password</swi-button
    >
    <swi-button
      *ngIf="
        vm.permissions?.manageOwnAvailability && vm.currentUser?.isPhotographer
      "
      (onClick)="openManageAvailabilityModal(vm.currentUser?.id)"
      >My Availability</swi-button
    >
    <swi-button (onClick)="syncToGoogleCalendar()"
      >Sync to Google Calendar</swi-button
    >
    <swi-button (onClick)="logout()">Logout</swi-button>

    <nav mat-tab-nav-bar mat-stretch-tabs="false" [tabPanel]="tabPanel">
      <a
        *ngIf="tabMyCalendarEnabled"
        mat-tab-link
        routerLink="/admin/my-calendar"
        [active]="router.url.includes('my-calendar')"
        >My Calendar
      </a>
      <a
        *ngIf="tabUsersEnabled"
        mat-tab-link
        routerLink="/admin/users"
        [active]="router.url.includes('users')"
        >Users
      </a>
      <a
        *ngIf="tabBookingsEnabled"
        mat-tab-link
        routerLink="/admin/bookings"
        [active]="router.url.includes('bookings')"
        >Bookings
      </a>
    </nav>

    <mat-tab-nav-panel #tabPanel>
      <router-outlet></router-outlet>
    </mat-tab-nav-panel>
  </ng-container>

  <ng-template #awaitingToken> Awaiting Session Token... </ng-template>
</ng-container>
