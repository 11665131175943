import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import {
  AppState,
  changePassword,
  getIsChangingPassword,
  getPasswordSuccess,
} from 'src/app/+state';
import { PASSWORD_PATTERN } from 'src/app/constants';
import { ChangePasswordRequest } from 'src/app/models';

@Component({
  selector: 'swi-change-my-password-form',
  templateUrl: './change-my-password-form.component.html',
  styleUrls: ['./change-my-password-form.component.scss'],
})
export class ChangeMyPasswordFormComponent {
  formGroup: FormGroup;

  isChangingPassword$ = this.appState.select(getIsChangingPassword);

  constructor(
    private appState: Store<AppState>,
    private dialogRef: MatDialogRef<ChangeMyPasswordFormComponent>,
    fb: FormBuilder
  ) {
    this.formGroup = fb.group({
      password: ['', [Validators.required, Validators.minLength(8)]],
      newPassword: [
        '',
        [Validators.required, Validators.pattern(PASSWORD_PATTERN)],
      ],
      confirmPassword: ['', Validators.required],
    });
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  formIsValid(): boolean {
    return this.formGroup.valid && this.passwordsMatch();
  }

  passwordsMatch(): boolean {
    return (
      this.formGroup.get('newPassword')?.value ===
      this.formGroup.get('confirmPassword')?.value
    );
  }

  submit(): void {
    const formValues = this.formGroup.getRawValue();
    const request: ChangePasswordRequest = {
      oldPassword: formValues.password,
      newPassword: formValues.newPassword,
    };
    this.appState.dispatch(
      changePassword({ request, callback: () => this.closeDialog() })
    );
  }
}
