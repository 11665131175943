import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Observable, map, of } from 'rxjs';
import { APP_COOKIE } from '../constants';
import { BasicResponse, LoginForm, LoginResponse } from '../models';

@Injectable({
  providedIn: 'root',
})
export class SessionService {
  constructor(private cookie: CookieService, private http: HttpClient) {}

  login(request: LoginForm): Observable<LoginResponse> {
    const url = '/Login';
    return this.http.post<LoginResponse>(url, request);
  }

  logout(): Observable<BasicResponse> {
    if (!this.cookie.check(APP_COOKIE)) {
      return of({ success: true });
    }
    const appCookie: LoginResponse = JSON.parse(this.cookie.get(APP_COOKIE));
    this.cookie.delete(APP_COOKIE);
    if (!appCookie.token) {
      return of({ success: true });
    }
    const url = '/Logout';
    return this.http.post<BasicResponse>(url, appCookie);
  }

  verifyToken(): Observable<LoginResponse | null> {
    if (!this.cookie.check(APP_COOKIE)) {
      return of(null);
    }
    const appCookie: LoginResponse = JSON.parse(this.cookie.get(APP_COOKIE));
    if (
      !appCookie.expiration ||
      !appCookie.token ||
      appCookie.expiration < Date.now()
    ) {
      this.cookie.delete(APP_COOKIE);
      return of(null);
    }
    const url = '/VerifyToken';
    return this.http.post<boolean>(url, appCookie).pipe(
      map((isTokenValid) => {
        if (!isTokenValid) {
          this.cookie.delete(APP_COOKIE);
          return null;
        } else {
          return appCookie;
        }
      })
    );
  }
}
