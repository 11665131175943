import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, switchMap } from 'rxjs';
import * as FeatureFlagsActions from 'src/app/+state/actions/feature-flags.actions';
import { FeatureFlagService } from 'src/app/services';

@Injectable()
export class FeatureFlagsEffects {
  loadFeatureFlags$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FeatureFlagsActions.loadFeatureFlags),
      switchMap(() =>
        this.featureFlagsService.getFeatureFlags().pipe(
          map((featureFlags) => {
            return FeatureFlagsActions.loadFeatureFlagsSuccess({
              featureFlags,
            });
          }),
          catchError(() => of(FeatureFlagsActions.loadFeatureFlagsFailure()))
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private featureFlagsService: FeatureFlagService
  ) {}
}
