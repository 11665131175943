import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BasicResponse, ChangePasswordRequest } from '../models';

@Injectable({
  providedIn: 'root',
})
export class AccountService {
  constructor(private http: HttpClient) {}

  changePassword(request: ChangePasswordRequest): Observable<BasicResponse> {
    const url = '/ChangePassword';
    return this.http.post<BasicResponse>(url, request);
  }

  forgotPassword(email: string): Observable<BasicResponse> {
    const url = '/ForgotPassword';
    return this.http.post<BasicResponse>(url, { email });
  }
}
