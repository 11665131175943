import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class GoogleCalendarService {
  baseUrl: string;

  constructor(private http: HttpClient) {
    this.baseUrl = '/Google';
  }

  loadConsentPageUrl(): Observable<{ url: string }> {
    console.log('Here2');
    const url = `${this.baseUrl}/CalendarSync`;
    return this.http.get<{ url: string }>(url);
  }
}
