import { createSelector } from '@ngrx/store';
import * as reducers from '../reducers';

export const getAvailabilityState = createSelector(
  reducers.getAppState,
  (state: reducers.AppState) => state.availability
);

export const getAvailableDates = createSelector(
  getAvailabilityState,
  (state) => state.availableDates
);

export const getAvailabilityEntities = createSelector(
  getAvailabilityState,
  (state) => state.availabilityEntities
);

export const getIsLoadingAvailableDates = createSelector(
  getAvailabilityState,
  (state) => state.isLoadingAvailableDates
);

export const getIsLoadingAvailability = createSelector(
  getAvailabilityState,
  (state) => state.isLoadingAvailability
);

export const getIsUpdatingAvailability = createSelector(
  getAvailabilityState,
  (state) => state.isUpdatingAvailability
);

export const getLoadAvailableDatesRequest = createSelector(
  getAvailabilityState,
  (state) => state.loadAvailableDatesRequest
);

export const getLoadedDate = createSelector(
  getAvailabilityState,
  (state) => state.loadedDate
);
