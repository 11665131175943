import { Component } from '@angular/core';

@Component({
  selector: 'swi-contact-link',
  templateUrl: './contact-link.component.html',
  styleUrls: ['./contact-link.component.scss']
})
export class ContactLinkComponent {

}
