import { createSelector } from '@ngrx/store';
import * as reducers from '../reducers';

export const getAppSettingsState = createSelector(
  reducers.getAppState,
  (state: reducers.AppState) => state.appSettings
);

export const getAppSettings = createSelector(
  getAppSettingsState,
  (state) => state.appSettings
);

export const getIsLoadingAppSettings = createSelector(
  getAppSettingsState,
  (state) => state.isLoadingAppSettings
);
