import { Component, HostListener, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState, getOverlayOpen } from 'src/app/+state';

@Component({
  selector: 'swi-scroll-top',
  templateUrl: './scroll-top.component.html',
  styleUrls: ['./scroll-top.component.scss'],
})
export class ScrollTopComponent implements OnInit {
  displayScrollTop = false;
  overlayOpen = false;

  constructor(private appState: Store<AppState>) {}

  ngOnInit(): void {
    this.appState
      .select(getOverlayOpen)
      .subscribe((open) => (this.overlayOpen = open));
  }

  @HostListener('window:scroll', ['$event'])
  scrollListener() {
    this.displayScrollTop = window.scrollY > 500;
  }

  scrollToTop(): void {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
}
