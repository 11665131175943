import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BasicResponse, ContactForm } from '../models';

@Injectable({
  providedIn: 'root',
})
export class MailClientService {
  constructor(private http: HttpClient) {}

  sendMessage(request: ContactForm): Observable<BasicResponse> {
    const url = '/SendMessage';
    return this.http.post<BasicResponse>(url, request);
  }
}
