<div class="content-with-image">
  <div class="content">
    <h1 class="header">Locations</h1>
    No matter what area you are staying at on the Gulf Coast, you will find
    beauty everywhere you look. Our area is filled with sugar white sand,
    emerald colored water, and arguably the best sunsets in Florida. Over the
    years, our team at Saltwater Images has worked hard to find the most
    beautiful spots in each area for our shoots to take place. A lot of thought
    has gone into these locations & they all provide beautiful open beaches,
    gorgeous dune backdrops, parking, & handicap accessible options.
    <br />
    <br />
    <strong>Destin:</strong> Henderson Beach State Park
    <br />
    <br />
    <strong>Fort Walton:</strong> John Beasley Park
    <br />
    <br />
    <strong>Navarre:</strong> Navarre Beach Marine Park
    <br />
    <br />
    <strong>Pensacola:</strong> Park West OR Fort Pickens
    <br />
    <br />
    <strong>Perdido Key:</strong> Johnson Beach
    <br />
    <br />
    <strong>Orange Beach/Gulf Shores:</strong> Florida Point - Gulf State Park
  </div>
  <div class="image">
    <img src="./assets/images/siblings-beach-sunset.jpg" />
  </div>
</div>
