import { Action, createReducer, on } from '@ngrx/store';
import * as FeatureFlagsActions from '../actions/feature-flags.actions';
import { FeatureFlags } from 'src/app/models';

export interface State {
  featureFlags: FeatureFlags | null;
}

const initialState: State = {
  featureFlags: null,
};

const featureFlagsReducer = createReducer(
  initialState,
  on(
    FeatureFlagsActions.loadFeatureFlagsSuccess,
    (state, { featureFlags }) => ({
      ...state,
      featureFlags,
    })
  )
);

export function reducer(state: State | undefined, action: Action) {
  return featureFlagsReducer(state, action);
}
