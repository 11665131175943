import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { combineLatest, map } from 'rxjs';
import { AppState, getCurrentUserPermissions } from 'src/app/+state';

@Component({
  selector: 'swi-bookings-tab',
  templateUrl: './bookings-tab.component.html',
  styleUrls: ['./bookings-tab.component.scss'],
})
export class BookingsTabComponent {
  permissions$ = this.appState.select(getCurrentUserPermissions);

  vm$ = combineLatest([this.permissions$]).pipe(
    map(([permissions]) => ({
      permissions,
    }))
  );

  constructor(private appState: Store<AppState>, private router: Router) {}

  goToEditBookingPage(): void {
    this.router.navigate(['admin', 'bookings', 'create']);
  }
}
