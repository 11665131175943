import { createSelector } from '@ngrx/store';
import * as reducers from '../reducers';

export const getGalleryState = createSelector(
  reducers.getAppState,
  (state: reducers.AppState) => state.gallery
);

export const getOverlayOpen = createSelector(
  getGalleryState,
  (state) => state.overlayOpen
);
