import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import {
  AppState,
  forgotPassword,
  getIsLoggingIn,
  login,
} from 'src/app/+state';

@Component({
  selector: 'swi-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss'],
})
export class LoginPageComponent {
  loginForm: FormGroup;

  isLoggingIn$ = this.appState.select(getIsLoggingIn);

  get email() {
    return this.loginForm.get('email');
  }

  get password() {
    return this.loginForm.get('password');
  }

  constructor(
    private appState: Store<AppState>,
    fb: FormBuilder,
    private router: Router
  ) {
    this.loginForm = fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
    });
  }

  forgotPassword(): void {
    if (this.loginForm.get('email')?.valid) {
      const email = this.loginForm.get('email')?.value;
      this.appState.dispatch(forgotPassword({ email }));
    } else {
      console.error('Handle invalid email with Forgot Password flow.');
    }
  }

  submit(): void {
    const loginForm = { ...this.loginForm.getRawValue() };
    this.appState.dispatch(
      login({ loginForm, callback: () => this.router.navigateByUrl('/admin') })
    );
  }
}
