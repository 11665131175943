<div class="overlap-content-img">
  <div class="img-container">
    <img id="currentImage" [src]="activeImageSrc" />
    <img *ngIf="showNextImage" [@fade] id="nextImage" [src]="nextImageSrc" />
  </div>
  <div class="content-container">
    <div class="header">GULF COAST'S PREMIERE FAMILY PHOTOGRAPHY TEAM</div>
    <div class="message">
      CAPTURING FUN FAMILY MOMENTS & UNFORGETTABLE MEMORIES YOU WILL CHERISH FOR
      YEARS TO COME
    </div>
    <div class="partial-divider"></div>
    <div class="message2">
      Let's freeze those moments & preserve them for a lifetime.
    </div>
  </div>
</div>
