import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { CookieService } from 'ngx-cookie-service';
import {
  AppState,
  loadAllLocations,
  loadAppSettings,
  loadFeatureFlags,
  verifyToken,
} from './+state';
import { CgPhotosInfoModalComponent } from './components/cg-photos-info-modal/cg-photos-info-modal.component';
import { CG_PHOTOS_INFO_DISMISSED_COOKIE } from './constants';
import { GtmService } from './services';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(
    private appState: Store<AppState>,
    private cookieService: CookieService,
    private dialog: MatDialog,
    private gtmService: GtmService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.subscribeToRouterEvents();
    this.appState.dispatch(loadFeatureFlags());
    this.appState.dispatch(loadAppSettings());
    this.appState.dispatch(verifyToken({}));
    this.appState.dispatch(loadAllLocations());
  }

  openCgPhotosInfoModal(): void {
    const dismissed = this.cookieService.get(CG_PHOTOS_INFO_DISMISSED_COOKIE);
    if (dismissed) return;
    this.dialog
      .open(CgPhotosInfoModalComponent, { width: '800px', autoFocus: false })
      .afterClosed()
      .subscribe(() => {
        this.cookieService.set(CG_PHOTOS_INFO_DISMISSED_COOKIE, 'Dismissed', {
          expires: 365,
        });
      });
  }

  pushPageLoadsToGtm(url: string): void {
    const gtmTag = {
      event: 'pageLoad',
      pageName: url,
    };

    this.gtmService.pushTag(gtmTag);
  }

  subscribeToRouterEvents(): void {
    this.router.events.forEach((item) => {
      if (item instanceof NavigationEnd) {
        this.pushPageLoadsToGtm(item.url);
        if (item.url.includes('redirected=cgphotos')) {
          this.openCgPhotosInfoModal();
          const gtmTag = {
            event: 'cgphotosRedirect',
          };

          this.gtmService.pushTag(gtmTag);
        }
      }
    });
  }
}
