import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { combineLatest, map, tap } from 'rxjs';
import {
  AppState,
  getAvailableDates,
  getLoadAvailableDatesRequest,
  getLoadedDate,
  loadAvailableDates,
  updateAvailableDates,
} from 'src/app/+state';
import { UserAvailability, getUserAvailabilityText } from 'src/app/enums';
import {
  AvailableDate,
  LoadAvailableDatesRequest,
  UpdateAvailableDateRequest,
} from 'src/app/models';
import {
  getFirstDayOfMonth,
  getMonthName,
  getNumberOfDaysInMonth,
  swiCreateDate,
} from 'src/app/utils';
import { BookingOptionsModalComponent } from '../booking-options-modal/booking-options-modal.component';
import { BookingOptionsModalData } from '../booking-options-modal/booking-options-modal-data.model';
import { Router } from '@angular/router';

@Component({
  selector: 'swi-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
})
export class CalendarComponent {
  availableDates: AvailableDate[] = [];
  offsetArray: null[] = [];
  firstDayOfMonth: Date = new Date();
  getMonthName = getMonthName;
  getUserAvailabilityText = getUserAvailabilityText;
  loadAvailableDatesRequest: LoadAvailableDatesRequest | null = null;
  month: number = 0;
  numberOfDays: number = 0;
  swiCreateDate = swiCreateDate;
  today: Date;
  UserAvailability = UserAvailability;
  weekDays = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ];
  year: number = 0;

  availableDates$ = this.appState.select(getAvailableDates).pipe(
    tap(
      (ads) =>
        (this.availableDates = ads.map((ad) => ({
          ...ad,
          available:
            ad.available || ad.availability === UserAvailability.Available,
        })))
    )
  );
  date$ = this.appState.select(getLoadedDate).pipe(
    map((d) => {
      if (!d) return d;
      const date = new Date(d);
      this.month = date.getMonth();
      this.year = date.getFullYear();
      this.firstDayOfMonth = getFirstDayOfMonth(this.month, this.year);
      this.numberOfDays = getNumberOfDaysInMonth(this.month, this.year);
      this.offsetArray = [
        ...Array(this.firstDayOfMonth.getDay()).map(() => null),
      ];
      return date;
    })
  );
  loadAvailableDatesRequest$ = this.appState
    .select(getLoadAvailableDatesRequest)
    .pipe(tap((req) => (this.loadAvailableDatesRequest = req)));

  vm$ = combineLatest([
    this.availableDates$,
    this.date$,
    this.loadAvailableDatesRequest$,
  ]).pipe(
    map(([availableDates, date, loadAvailableDatesRequest]) => ({
      availableDates,
      date,
      loadAvailableDatesRequest,
    }))
  );

  constructor(
    private appState: Store<AppState>,
    private dialog: MatDialog,
    private router: Router
  ) {
    this.today = new Date();
    this.today.setHours(0, 0, 0, 0);
  }

  dateToggled(
    day: number,
    available: boolean,
    userId: string | null | undefined
  ): void {
    const request: UpdateAvailableDateRequest = {
      available,
      date: swiCreateDate(this.year, this.month, day),
      userId: userId ?? '',
    };
    this.appState.dispatch(updateAvailableDates({ request }));
  }

  dayClicked(day: number, bookable: boolean): void {
    if (!bookable) return;
    // this.openBookingOptionsModal(day);
    this.gotoContactForm(day);
  }

  getIsAvailable(day: number | null): boolean {
    if (!day) return false;
    return !!this.availableDates.find((d) => d.date === day)?.available;
  }

  gotoContactForm(day: number): void {
    const date = swiCreateDate(this.year, this.month, day);
    this.router.navigate(['contact'], {
      queryParams: {
        date: date.toISOString(),
        location: this.loadAvailableDatesRequest?.location,
      },
    });
  }

  isInThePast(day: number): boolean {
    const date = swiCreateDate(this.year, this.month, day);
    return date < this.today;
  }

  openBookingOptionsModal(day: number): void {
    const data: BookingOptionsModalData = {
      date: swiCreateDate(this.year, this.month, day),
      location: this.loadAvailableDatesRequest?.location as string,
    };
    this.dialog
      .open(BookingOptionsModalComponent, {
        data,
      })
      .afterClosed()
      .subscribe((res) => {
        if (res?.refresh) {
          this.appState.dispatch(
            loadAvailableDates({
              request: this
                .loadAvailableDatesRequest as LoadAvailableDatesRequest,
            })
          );
        }
      });
  }
}
