<ng-container *ngIf="vm$ | async as vm">
  <form [formGroup]="formGroup">
    <mat-checkbox color="primary" formControlName="monday">Monday</mat-checkbox>
    <mat-checkbox color="primary" formControlName="tuesday"
      >Tuesday</mat-checkbox
    >
    <mat-checkbox color="primary" formControlName="wednesday"
      >Wednesday</mat-checkbox
    >
    <mat-checkbox color="primary" formControlName="thursday"
      >Thursday</mat-checkbox
    >
    <mat-checkbox color="primary" formControlName="friday">Friday</mat-checkbox>
    <mat-checkbox color="primary" formControlName="saturday"
      >Saturday</mat-checkbox
    >
    <mat-checkbox color="primary" formControlName="sunday">Sunday</mat-checkbox>

    <mat-form-field>
      <mat-label>Max Forward Booking (Months)</mat-label>
      <input matInput formControlName="maxForwardBooking" />
      <mat-error
        *ngIf="formGroup.get('maxForwardBooking')?.hasError('required')"
        >Max Forward Booking is required.</mat-error
      >
      <mat-error
        *ngIf="
          formGroup.get('maxForwardBooking')?.hasError('pattern') ||
          formGroup.get('maxForwardBooking')?.hasError('min') ||
          formGroup.get('maxForwardBooking')?.hasError('max')
        "
        >Max Forward Booking must be an integer between 1 and 24.</mat-error
      >
    </mat-form-field>

    <div class="buttons-container">
      <swi-button [disabled]="formGroup.invalid" (onClick)="save()">
        Save
      </swi-button>
      <swi-button (onClick)="dialogRef.close()">Cancel</swi-button>
    </div>
  </form>
</ng-container>
