<ng-container *ngIf="vm$ | async as vm">
  <div class="form-container">
    <form [formGroup]="bookingForm">
      <mat-form-field>
        <mat-label>Status</mat-label>
        <mat-select
          formControlName="status"
          (selectionChange)="statusChanged($event.value)"
        >
          <mat-select-trigger>
            {{ getBookingStatusText(bookingForm.get("status")?.value) }}
          </mat-select-trigger>
          <mat-option
            *ngFor="let option of bookingStatusOptions"
            [value]="option.value"
            >{{ option.text }}</mat-option
          >
        </mat-select>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Name</mat-label>
        <input matInput formControlName="name" />
        <mat-error *ngIf="bookingForm.get('name')?.hasError('required')"
          >Name is required.</mat-error
        >
      </mat-form-field>
      <mat-form-field>
        <mat-label>Phone</mat-label>
        <input matInput formControlName="phone" />
        <mat-error *ngIf="bookingForm.get('phone')?.hasError('required')"
          >Phone is required.</mat-error
        >
        <mat-error *ngIf="bookingForm.get('phone')?.hasError('pattern')"
          >Phone number is not valid.</mat-error
        >
      </mat-form-field>
      <mat-form-field>
        <mat-label>Email</mat-label>
        <input matInput formControlName="email" />
        <mat-error *ngIf="bookingForm.get('email')?.hasError('required')"
          >Email is required.</mat-error
        >
        <mat-error *ngIf="bookingForm.get('email')?.hasError('email')"
          >Email is not valid.</mat-error
        >
      </mat-form-field>
      <mat-form-field>
        <mat-label>Choose a date</mat-label>
        <input
          matInput
          [matDatepicker]="datepicker"
          formControlName="date"
          (dateChange)="loadAvailablePhotographers()"
        />
        <mat-hint>MM/DD/YYYY</mat-hint>
        <mat-datepicker-toggle
          matIconSuffix
          [for]="datepicker"
        ></mat-datepicker-toggle>
        <mat-datepicker #datepicker>
          <mat-datepicker-actions>
            <button mat-button matDatepickerCancel>Cancel</button>
            <button mat-raised-button color="primary" matDatepickerApply>
              Apply
            </button>
          </mat-datepicker-actions>
        </mat-datepicker>
        <mat-error *ngIf="bookingForm.get('date')?.hasError('required')"
          >Date is required.</mat-error
        >
      </mat-form-field>
      <mat-form-field>
        <mat-label>Location</mat-label>
        <mat-select
          formControlName="location"
          (selectionChange)="loadAvailablePhotographers()"
        >
          <mat-select-trigger>
            {{
              getLocationName(
                this.bookingForm.get("location")?.value,
                vm.locations
              )
            }}
          </mat-select-trigger>
          <mat-option
            *ngFor="let location of vm.locations || []"
            [value]="location.id"
            >{{ location.name }}</mat-option
          >
        </mat-select>
      </mat-form-field>
      <mat-form-field *ngIf="vm.photographers?.length">
        <mat-label>Photographer(s)</mat-label>
        <mat-select formControlName="photographers" multiple>
          <mat-select-trigger>
            {{ getPhotographersName(bookingForm.get('photographers')?.value?.[0] || '', vm.photographers) }}
            <span
              *ngIf="(bookingForm.get('photographers')?.value?.length || 0) > 1"
            >
              (+{{
                (bookingForm.get("photographers")?.value?.length || 0) - 1
              }}
              {{
                bookingForm.get("photographers")?.value?.length === 2
                  ? "other"
                  : "others"
              }})
            </span>
          </mat-select-trigger>
          <mat-option
            *ngFor="let photographer of vm.photographers"
            [disabled]="
              photographer.availability > 0 && !vm.permissions?.overrideBookings
            "
            [value]="photographer.id"
            >{{ photographer.firstName }} {{ photographer.lastName }} ({{
              getUserAvailabilityText(photographer.availability)
            }})</mat-option
          >
        </mat-select>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Notes</mat-label>
        <textarea matInput formControlName="notes"></textarea>
      </mat-form-field>
      <div class="buttons-container">
        <swi-button
          (onClick)="save()"
          [disabled]="bookingForm.invalid"
          [isProcessing]="false"
          >Submit</swi-button
        >
        <swi-button (onClick)="cancel()">Cancel</swi-button>
      </div>
    </form>
  </div>
</ng-container>
