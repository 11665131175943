import { AccountEffects } from './account.effects';
import { AppSettingsEffects } from './app-settings.effects';
import { AvailabilityEffects } from './availability.effects';
import { BookingsEffects } from './bookings.effects';
import { FeatureFlagsEffects } from './feature-flags.effects';
import { GoogleCalendarEffects } from './google-calendar.effects';
import { LocationsEffects } from './locations.effects';
import { MailClientEffects } from './mail-client.effects';
import { PayPalEffects } from './paypal.effects';
import { PhotographersEffects } from './photographers.effects';
import { SessionEffects } from './session.effects';
import { UsersEffects } from './users.effects';

export const Effects = [
  AccountEffects,
  AppSettingsEffects,
  AvailabilityEffects,
  BookingsEffects,
  FeatureFlagsEffects,
  GoogleCalendarEffects,
  LocationsEffects,
  MailClientEffects,
  PayPalEffects,
  PhotographersEffects,
  SessionEffects,
  UsersEffects,
];

export * from './account.effects';
export * from './app-settings.effects';
export * from './availability.effects';
export * from './bookings.effects';
export * from './feature-flags.effects';
export * from './google-calendar.effects';
export * from './locations.effects';
export * from './mail-client.effects';
export * from './paypal.effects';
export * from './photographers.effects';
export * from './session.effects';
export * from './users.effects';
