import { createAction, props } from '@ngrx/store';
import { AppSettings, BasicResponse } from 'src/app/models';

export const loadAppSettings = createAction('[AppSettings] Load App Settings');

export const loadAppSettingsSuccess = createAction(
  '[Account] Load App Settings Success',
  props<{ appSettings: AppSettings }>()
);

export const loadAppSettingsFailure = createAction(
  '[Account] Load App Settings Failure',
  props<{ response: BasicResponse }>()
);
