import { Component, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UserFormComponent } from '../user-form/user-form.component';
import { UsersListComponent } from '../users-list/users-list.component';

@Component({
  selector: 'swi-users-tab',
  templateUrl: './users-tab.component.html',
  styleUrls: ['./users-tab.component.scss'],
})
export class UsersTabComponent {
  @ViewChild('usersList') usersList!: UsersListComponent;

  constructor(private dialog: MatDialog) {}

  openAddNewUserModal(): void {
    const dialogRef = this.dialog.open(UserFormComponent);

    dialogRef.afterClosed().subscribe((res) => {
      if (res?.success) {
        this.usersList.loadUsers();
      }
    });
  }
}
