import { Injectable } from '@angular/core';
import { WindowReferenceService } from './window-reference.service';
import { GoogleTag } from '../models';

@Injectable({
  providedIn: 'root',
})
export class GtmService {
  private window;

  constructor(windowRef: WindowReferenceService) {
    this.window = windowRef.nativeWindow; // intialise the window to what we get from our window service
  }

  pushTag(tag: GoogleTag) {
    if (tag) {
      this.window.dataLayer.push(tag);
    }
  }
}
