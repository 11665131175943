<ng-container *ngIf="vm$ | async as vm">
  <section>
    <h2>Select a location and month to display available dates.</h2>

    <div class="legend">
      <div class="legend-color green"></div>
      Available Day
    </div>

    <swi-calendar-filter></swi-calendar-filter>
    <swi-calendar *ngIf="!vm.isLoading; else spinner"></swi-calendar>

    <ng-template #spinner><mat-spinner></mat-spinner></ng-template>
  </section>
</ng-container>
