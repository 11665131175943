import { createAction, props } from '@ngrx/store';
import {
  BasicResponse,
  SimpleUser,
  SimpleUserAvailability,
} from 'src/app/models';

export const clearPhotographers = createAction(
  '[Photographers] Clear Photographers'
);

export const clearPhotographersAvailable = createAction(
  '[Photographers] Clear Photographers Available'
);

export const loadPhotographers = createAction(
  '[Photographers] Load Photographers'
);

export const loadPhotographersSuccess = createAction(
  '[Photographers] Load Photographers Success',
  props<{
    photographers: SimpleUser[];
  }>()
);

export const loadPhotographersFailure = createAction(
  '[Photographers] Load Photographers Failure',
  props<{ response: BasicResponse }>()
);

export const loadPhotographersAvailable = createAction(
  '[Photographers] Load Photographers Available',
  props<{ date: string; location: string }>()
);

export const loadPhotographersAvailableSuccess = createAction(
  '[Photographers] Load Photographers Available Success',
  props<{
    photographers: SimpleUserAvailability[];
  }>()
);

export const loadPhotographersAvailableFailure = createAction(
  '[Photographers] Load Photographers Available Failure',
  props<{ response: BasicResponse }>()
);
