<div *ngIf="vm$ | async as vm" class="form-container">
  <div class="contact-image">
    <img src="./assets/images/family-photography-gallery-21.jpg" />
  </div>

  <div class="top-message">
    <h2 class="chat-header">Let's Chat!</h2>
    <p>
      We would love to hear from you! Please fill out this form and we will be
      in touch with you within 24 hours!
    </p>
    <p>
      Or if you prefer to call:
      <a
        href="tel:{{
          vm.appSettings?.primaryContact?.phone?.raw || '8502939933'
        }}"
        >{{
          vm.appSettings?.primaryContact?.phone?.pretty || "(850) 293-9933"
        }}</a
      >
    </p>
    <p>
      Or text us at:
      <a
        href="sms:{{
          vm.appSettings?.primaryContact?.phone?.raw || '8502939933'
        }}"
        >{{
          vm.appSettings?.primaryContact?.phone?.prettyDash || "850-293-9933"
        }}</a
      >
    </p>
  </div>

  <form [formGroup]="contactForm">
    <div class="columns">
      <div class="left-column">
        <div class="form-field">
          <div class="swi-control">
            <div>* Name</div>
            <input formControlName="name" />
          </div>
          <div class="hint-wrapper">
            <span
              *ngIf="
                contactForm.get('name')?.touched &&
                contactForm.get('name')?.hasError('required')
              "
              >Name is required.</span
            >
          </div>
        </div>
        <div class="form-field">
          <div class="swi-control">
            <div>Date Range</div>
            <input formControlName="dateRange" />
          </div>
          <div class="hint-wrapper"></div>
        </div>
        <!-- <div class="form-field">
          <div class="swi-control">
            <div>* Location</div>
            <select formControlName="location">
              <option
                *ngFor="let location of vm.locations"
                [value]="location.id"
              >
                {{ location.name }} | {{ location.spot }}
              </option>
            </select>
          </div>
          <div class="hint-wrapper">
            <span
              *ngIf="
                contactForm.get('location')?.touched &&
                contactForm.get('location')?.hasError('required')
              "
              >Location is required.</span
            >
          </div>
        </div> -->
      </div>

      <div class="right-column">
        <div class="form-field">
          <div class="swi-control">
            <div>* Phone</div>
            <input formControlName="phone" />
          </div>
          <div class="hint-wrapper">
            <ng-container *ngIf="contactForm.get('phone')?.touched">
              <span *ngIf="contactForm.get('phone')?.hasError('required')"
                >Phone is required.</span
              >
              <span *ngIf="contactForm.get('phone')?.hasError('pattern')"
                >Phone number is not valid.</span
              >
            </ng-container>
          </div>
        </div>
        <!-- <div class="form-field">
          <div class="swi-control">
            <div>Email</div>
            <input formControlName="email" />
          </div>
          <div class="hint-wrapper">
            <ng-container *ngIf="contactForm.get('email')?.touched">
              <span *ngIf="contactForm.get('email')?.hasError('email')"
                >Email is not valid.</span
              >
            </ng-container>
          </div>
        </div> -->
        <div class="form-field">
          <div class="swi-control">
            <div>* Party Size</div>
            <input formControlName="partySize" />
          </div>
          <div class="hint-wrapper">
            <span
              *ngIf="
                contactForm.get('partySize')?.touched &&
                contactForm.get('partySize')?.hasError('required')
              "
              >Party size is required.</span
            >
          </div>
        </div>
      </div>
    </div>

    <div class="form-field">
      <div class="swi-control">
        <div>Email</div>
        <input formControlName="email" />
      </div>
      <div class="hint-wrapper">
        <ng-container *ngIf="contactForm.get('email')?.touched">
          <span *ngIf="contactForm.get('email')?.hasError('email')"
            >Email is not valid.</span
          >
        </ng-container>
      </div>
    </div>

    <div class="form-field">
      <div class="swi-control textarea">
        <div>* Message</div>
        <textarea formControlName="message" rows="6"></textarea>
      </div>
      <div class="hint-wrapper">
        <span
          *ngIf="
            contactForm.get('message')?.touched &&
            contactForm.get('message')?.hasError('required')
          "
          >Message is required.</span
        >
      </div>
    </div>
    <swi-button
      [disabled]="contactForm.invalid"
      [isPrimary]="true"
      [isProcessing]="vm.isSendingMessage"
      (onClick)="sendMessage()"
      [pretty]="true"
      >Send
    </swi-button>
  </form>
  <div class="buttons-container">
    <swi-button [isPrimary]="true" [pretty]="true"
      ><a
        class="anchor-button"
        href="tel:{{
          vm.appSettings?.primaryContact?.phone?.raw || '8502939933'
        }}"
        >Call Now</a
      >
    </swi-button>
    <swi-button [isPrimary]="true" [pretty]="true"
      ><a
        class="anchor-button"
        href="sms:{{
          vm.appSettings?.primaryContact?.phone?.raw || '8502939933'
        }}"
        >Text Now</a
      >
    </swi-button>
  </div>
</div>
