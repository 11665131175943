import { createSelector } from '@ngrx/store';
import * as reducers from '../reducers';

export const getLocationsState = createSelector(
  reducers.getAppState,
  (state: reducers.AppState) => state.locations
);

export const getIsLoadingLocations = createSelector(
  getLocationsState,
  (state) => state.isLoadingLocations
);

export const getAllLocations = createSelector(
  getLocationsState,
  (state) => state.locations
);
