export function swiCreateDate(year: number, month: number, day: number): Date {
  return new Date(
    year +
      '-' +
      getTwoDigitString(month + 1) +
      '-' +
      getTwoDigitString(day) +
      'T00:00'
  );
}

export function getFirstDayOfMonth(month: number, year: number): Date {
  return swiCreateDate(year, month, 1);
}

export function getMonthName(month: number): string | null {
  switch (month) {
    case 0:
      return 'January';
    case 1:
      return 'February';
    case 2:
      return 'March';
    case 3:
      return 'April';
    case 4:
      return 'May';
    case 5:
      return 'June';
    case 6:
      return 'July';
    case 7:
      return 'August';
    case 8:
      return 'September';
    case 9:
      return 'October';
    case 10:
      return 'November';
    case 11:
      return 'December';
    default:
      return null;
  }
}

export function getNumberOfDaysInMonth(month: number, year: number): number {
  return new Date(year, month, 0).getDate();
}

export function getTwoDigitString(number: number): string {
  return number < 10 ? '0' + number : number.toString();
}
