import { Component, Inject } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { combineLatest, map } from 'rxjs';
import {
  AppState,
  getIsRequestingInvoice,
  requestInvoice,
} from 'src/app/+state';
import { PHONE_PATTERN } from 'src/app/constants';
import { CreateInvoiceRequest } from 'src/app/models';
import { BookingOptionsModalData } from './booking-options-modal-data.model';

@Component({
  selector: 'swi-booking-options-modal',
  templateUrl: './booking-options-modal.component.html',
  styleUrls: ['./booking-options-modal.component.scss'],
})
export class BookingOptionsModalComponent {
  invoiceRequestForm = this.fb.group({
    email: ['', [Validators.required, Validators.email]],
    phone: ['', Validators.pattern(PHONE_PATTERN)],
    name: ['', Validators.required],
  });
  showInvoiceRequestForm = false;

  isRequestingInvoice$ = this.appState.select(getIsRequestingInvoice);

  vm$ = combineLatest([this.isRequestingInvoice$]).pipe(
    map(([isRequestingInvoice]) => ({ isRequestingInvoice }))
  );

  constructor(
    private appState: Store<AppState>,
    @Inject(MAT_DIALOG_DATA) public data: BookingOptionsModalData,
    public dialogRef: MatDialogRef<BookingOptionsModalComponent>,
    private fb: FormBuilder
  ) {}

  payPalCall(): void {
    const formValues = this.invoiceRequestForm.getRawValue();
    const request: CreateInvoiceRequest = {
      date: this.data.date,
      email: formValues.email as string,
      location: this.data.location,
      name: formValues.name as string,
      phone: formValues.phone as string,
    };
    const callback = () => this.dialogRef.close({ refresh: true });
    this.appState.dispatch(requestInvoice({ request, callback }));
  }
}
