import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, map } from 'rxjs';
import { AppState, getIsLoadingAvailableDates } from 'src/app/+state';

@Component({
  selector: 'swi-booking-page',
  templateUrl: './booking-page.component.html',
  styleUrls: ['./booking-page.component.scss'],
})
export class BookingPageComponent {
  isLoadingAvailableDates$ = this.appState.select(getIsLoadingAvailableDates);

  vm$ = combineLatest([this.isLoadingAvailableDates$]).pipe(
    map(([isLoading]) => ({ isLoading }))
  );

  constructor(private appState: Store<AppState>) {}
}
