export enum DayOfWeek {
  Sunday,
  Monday,
  Tuesday,
  Wednesday,
  Thursday,
  Friday,
  Saturday,
}

export function getDayOfWeekByName(name: string): DayOfWeek | null {
  switch (name.toLowerCase()) {
    case 'sunday':
      return DayOfWeek.Sunday;
    case 'monday':
      return DayOfWeek.Monday;
    case 'tuesday':
      return DayOfWeek.Tuesday;
    case 'wednesday':
      return DayOfWeek.Wednesday;
    case 'thursday':
      return DayOfWeek.Thursday;
    case 'friday':
      return DayOfWeek.Friday;
    case 'saturday':
      return DayOfWeek.Saturday;
    default:
      return null;
  }
}
