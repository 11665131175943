import { createAction, props } from '@ngrx/store';

export const loadConsentPageUrl = createAction(
  '[GoogleCalendar] Load Consent Page Url'
);

export const loadConsentPageUrlSuccess = createAction(
  '[GoogleCalendar] Load Consent Page Url Success',
  props<{ url: string }>()
);

export const loadConsentPageUrlFailure = createAction(
  '[GoogleCalendar] Load Consent Page Url Failure'
);
