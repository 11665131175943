import { createSelector } from '@ngrx/store';
import * as reducers from '../reducers';

export const getBookingsState = createSelector(
  reducers.getAppState,
  (state: reducers.AppState) => state.bookings
);

export const getBooking = createSelector(
  getBookingsState,
  (state) => state.booking
);

export const getBookings = createSelector(
  getBookingsState,
  (state) => state.bookings
);

export const getIsLoadingBooking = createSelector(
  getBookingsState,
  (state) => state.isLoadingBooking
);

export const getIsLoadingBookings = createSelector(
  getBookingsState,
  (state) => state.isLoadingBookings
);

export const getIsSavingBooking = createSelector(
  getBookingsState,
  (state) => state.isSavingBooking
);

export const getLoadBookingsParams = createSelector(
  getBookingsState,
  (state) => state.loadBookingsParams
);
