import { Component, Input } from '@angular/core';

@Component({
  selector: 'swi-overlapping-content2',
  templateUrl: './overlapping-content2.component.html',
  styleUrls: ['./overlapping-content2.component.scss'],
})
export class OverlappingContent2Component {
  @Input() image = './assets/images/large-family-beach.jpg';
}
