import { LocationsService, SnackBarService } from 'src/app/services';
import * as LocationsActions from 'src/app/+state/actions/locations.actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, switchMap } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable()
export class LocationsEffects {
  loadAllLocations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LocationsActions.loadAllLocations),
      switchMap(() =>
        this.locationsService.getAllLocations().pipe(
          map((locations) => {
            return LocationsActions.loadAllLocationsSuccess({ locations });
          }),
          catchError((response) => {
            this.snackBarService.openFailureSnackBar(
              response.error?.message || 'Failed to load locations.'
            );
            return of(LocationsActions.loadAllLocationsFailure({ response }));
          })
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private locationsService: LocationsService,
    private snackBarService: SnackBarService
  ) {}
}
