<section>
  <div class="image">
    <div class="image-container">
      <img src="./assets/images/kids-closeup.jpg" />
    </div>
  </div>
  <div class="content">
    <h1>ABOUT SALTWATER IMAGES</h1>
    Saltwater Images is a team of thoughtfully curated artists that are
    handpicked not only because of their camera skills but their people skill as
    well. We truly believe that the energy & personality behind the camera sets
    Saltwater Image Photographers above the rest.
    <br />
    <br />
    Our team is there to not only pose & guide you and your family through the
    session, but to also make it fun and memorable as well. When you leave you
    session, we want you to remember it as a fun addition to your vacation that
    you can't wait to do again next year.
    <br />
    <br />
    Our sessions are full of laughter, smiles, jokes, & genuine kindness. We
    love what we do, and we want all our clients to see that through our work
    before, during, & after the session.
  </div>
</section>
