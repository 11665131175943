import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'swi-cg-photos-info-modal',
  templateUrl: './cg-photos-info-modal.component.html',
  styleUrl: './cg-photos-info-modal.component.scss',
})
export class CgPhotosInfoModalComponent {
  constructor(public dialogRef: MatDialogRef<CgPhotosInfoModalComponent>) {}
}
