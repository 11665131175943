import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Store } from '@ngrx/store';
import { combineLatest, map, tap } from 'rxjs';
import {
  AppState,
  clearAvailability,
  getAllLocations,
  loadAvailableDates,
} from 'src/app/+state';
import { LoadAvailableDatesRequest, Location } from '../../models';
import { getFirstDayOfMonth, getMonthName } from 'src/app/utils';

@Component({
  selector: 'swi-calendar-filter',
  templateUrl: './calendar-filter.component.html',
  styleUrls: ['./calendar-filter.component.scss'],
})
export class CalendarFilterComponent implements OnDestroy, OnInit {
  @Input() userId: string | undefined;
  location = new FormControl('');
  month = new FormControl<{ text: string; value: Date }>({
    text: '',
    value: new Date(),
  });

  locationsList: Location[] = [];
  monthsList: { text: string; value: Date }[] = [];
  numMonths = 24;
  today = new Date();

  locations$ = this.appState.select(getAllLocations).pipe(
    tap((locations) => {
      if (locations) {
        this.locationsList = locations;
      }
    })
  );

  vm$ = combineLatest([this.locations$]).pipe(
    map(([locations]) => ({ locations }))
  );

  constructor(private appState: Store<AppState>) {
    let month = this.today.getMonth();
    let year = this.today.getFullYear();
    for (let i = 0; i < this.numMonths; i++) {
      this.monthsList.push({
        text: `${getMonthName(month)} - ${year}`,
        value: getFirstDayOfMonth(month, year),
      });
      if (month < 11) {
        month++;
      } else {
        month = 0;
        year++;
      }
    }
    this.month.setValue(this.monthsList[0]);
  }

  ngOnDestroy(): void {
    this.appState.dispatch(clearAvailability());
  }

  ngOnInit(): void {
    if (this.userId) {
      const request: LoadAvailableDatesRequest = {
        date: this.today.toISOString(),
        userId: this.userId,
      };
      this.loadAvailableDates(request);
    }
  }

  getLocationName(id: string): string {
    return this.locationsList.find((l) => l.id === id)?.name || '';
  }

  loadAvailableDates(request: LoadAvailableDatesRequest): void {
    this.appState.dispatch(loadAvailableDates({ request }));
  }

  locationSelected(location: string): void {
    const request: LoadAvailableDatesRequest = {
      date: this.month.value?.value.toISOString() || new Date().toISOString(),
      location,
    };
    this.loadAvailableDates(request);
  }

  monthSelected(month: { text: string; value: Date }): void {
    let request: LoadAvailableDatesRequest;
    if (this.userId) {
      request = {
        date: month.value.toISOString(),
        userId: this.userId,
      };
    } else {
      request = {
        date: month.value.toISOString(),
        location: this.location.value || undefined,
      };
    }
    this.loadAvailableDates(request);
  }
}
