<div class="form-container">
  <form [formGroup]="loginForm">
    <mat-form-field>
      <mat-label>Email</mat-label>
      <input matInput formControlName="email" />
      <mat-error *ngIf="email?.hasError('required')"
        >Email is required.</mat-error
      >
      <mat-error *ngIf="email?.hasError('email')"
        >Invalid email address.</mat-error
      >
    </mat-form-field>

    <mat-form-field>
      <mat-label>Password</mat-label>
      <input matInput formControlName="password" type="password" />
      <mat-error *ngIf="loginForm.get('password')?.hasError('required')"
        >Password is required.</mat-error
      >
    </mat-form-field>

    <a (click)="forgotPassword()">Forgot Password</a>

    <swi-button
      (onClick)="submit()"
      [disabled]="loginForm.invalid"
      [isProcessing]="(isLoggingIn$ | async) === true"
      >Login</swi-button
    >
  </form>
</div>
