import { createSelector } from '@ngrx/store';
import * as reducers from '../reducers';

export const getPayPalState = createSelector(
  reducers.getAppState,
  (state: reducers.AppState) => state.payPal
);

export const getIsRequestingInvoice = createSelector(
  getPayPalState,
  (state) => state.isRequestingInvoice
);
