import { Component } from '@angular/core';

@Component({
  selector: 'swi-content-with-image',
  templateUrl: './content-with-image.component.html',
  styleUrls: ['./content-with-image.component.scss']
})
export class ContentWithImageComponent {

}
