import { Action, createReducer, on } from '@ngrx/store';
import * as LocationsActions from '../actions/locations.actions';
import { Location } from 'src/app/models';

export interface State {
  isLoadingLocations: boolean;
  locations: Location[];
}

const initialState: State = {
  isLoadingLocations: false,
  locations: [],
};

const accountReducer = createReducer(
  initialState,
  on(LocationsActions.loadAllLocations, (state) => ({
    ...state,
    isLoadingLocations: true,
    locations: [],
  })),
  on(LocationsActions.loadAllLocationsSuccess, (state, { locations }) => ({
    ...state,
    isLoadingLocations: false,
    locations,
  })),
  on(LocationsActions.loadAllLocationsFailure, (state) => ({
    ...state,
    isLoadingLocations: false,
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return accountReducer(state, action);
}
