import { Action, createReducer, on } from '@ngrx/store';
import * as MailClientActions from '../actions/mail-client.actions';

export interface State {
  isSendingMessage: boolean;
}

const initialState: State = {
  isSendingMessage: false,
};

const mailClientReducer = createReducer(
  initialState,
  on(MailClientActions.sendMessage, (state) => ({
    ...state,
    isSendingMessage: true,
  })),
  on(
    MailClientActions.sendMessageSuccess,
    MailClientActions.sendMessageFailure,
    (state) => ({
      isSendingMessage: false,
    })
  )
);

export function reducer(state: State | undefined, action: Action) {
  return mailClientReducer(state, action);
}
