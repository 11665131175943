<ng-container *ngIf="vm$ | async as vm">
  <swi-button
    *ngIf="vm.permissions?.createBookings"
    (onClick)="goToEditBookingPage()"
    >Create Booking</swi-button
  >
  <swi-bookings-list
    *ngIf="
      vm.permissions?.manageOwnBookings || vm.permissions?.manageAllBookings
    "
  ></swi-bookings-list>
</ng-container>
