import { createAction, props } from '@ngrx/store';
import { BasicResponse, LoginForm, LoginResponse } from 'src/app/models';

export const login = createAction(
  '[Sessions] Login',
  props<{ loginForm: LoginForm; callback?: () => void }>()
);

export const loginSuccess = createAction(
  '[Sessions] Login Success',
  props<{ response: LoginResponse }>()
);

export const loginFailure = createAction(
  '[Sessions] Login Failure',
  props<{ response: BasicResponse }>()
);

export const logout = createAction(
  '[Sessions] Logout',
  props<{ callback?: () => void }>()
);

export const logoutSuccess = createAction(
  '[Sessions] Logout Success',
  props<{ response: BasicResponse }>()
);

export const logoutFailure = createAction(
  '[Sessions] Logout Failure',
  props<{ response: BasicResponse }>()
);

export const verifyToken = createAction(
  '[Sessions] Verify Token',
  props<{ callback?: () => void }>()
);

export const verifyTokenValid = createAction(
  '[Sessions] Verify Token Valid',
  props<{ response: LoginResponse }>()
);

export const verifyTokenInvalid = createAction(
  '[Sessions] Verify Token Invalid'
);
