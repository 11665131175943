import { Component } from '@angular/core';

@Component({
  selector: 'swi-about-page',
  templateUrl: './about-page.component.html',
  styleUrls: ['./about-page.component.scss']
})
export class AboutPageComponent {

}
