<button
  [ngClass]="{ disabled: disabled, pretty: pretty, primary: isPrimary }"
  (click)="onClicked()"
  [disabled]="disabled"
>
  <div [style.visibility]="isProcessing ? 'hidden' : 'visible'">
    <ng-content></ng-content>
  </div>

  <ng-container *ngIf="isProcessing"
    ><mat-spinner [diameter]="18" [strokeWidth]="1"></mat-spinner
  ></ng-container>
</button>
