<ng-container *ngIf="vm$ | async as vm">
  <section *ngIf="!vm.isMobileNav; else mobileNav" class="nav-container">
    <div
      class="nav-item"
      *ngFor="let navItem of navItems"
      [routerLink]="navItem.link"
    >
      {{ navItem.text }}
    </div>
  </section>

  <ng-template #mobileNav>
    <div class="mobile-nav" [mat-menu-trigger-for]="navMenu">
      <img src="./assets/images/hamburger-menu-icon.png" />
    </div>
    <mat-menu #navMenu="matMenu">
      <button
        *ngFor="let navItem of navItems"
        mat-menu-item
        [routerLink]="navItem.link"
      >
        {{ navItem.text }}
      </button>
    </mat-menu>
  </ng-template>
</ng-container>
