<ng-container *ngIf="vm$ | async as vm">
  <section class="filters-container">
    <mat-form-field>
      <mat-label>Photographers</mat-label>
      <mat-select
        [formControl]="photographers"
        multiple
        (selectionChange)="loadBookings()"
      >
        <mat-select-trigger>
          {{ getPhotographersSelectText() }}
        </mat-select-trigger>
        <mat-option
          *ngFor="let photographer of photographerList"
          [value]="photographer.id"
          >{{ getFullName(photographer) }}</mat-option
        >
      </mat-select>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Locations</mat-label>
      <mat-select
        [formControl]="locations"
        multiple
        (selectionChange)="loadBookings()"
      >
        <mat-select-trigger>
          {{ getLocationsSelectText() }}
        </mat-select-trigger>
        <mat-option
          *ngFor="let location of locationsList"
          [value]="location.id"
          >{{ location.name }}</mat-option
        >
      </mat-select>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Status</mat-label>
      <mat-select
        [formControl]="statuses"
        multiple
        (selectionChange)="loadBookings()"
      >
        <mat-select-trigger>
          {{ getBookingStatusSelectText() }}
        </mat-select-trigger>
        <mat-option
          *ngFor="let status of statusesList"
          [value]="status.value"
          >{{ status.text }}</mat-option
        >
      </mat-select>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Dates</mat-label>
      <mat-select [(value)]="dateOptionSelected">
        <mat-select-trigger>
          {{ getDatesSelectText() }}
        </mat-select-trigger>
        <mat-option
          *ngFor="let dateOption of dateOptions"
          [value]="dateOption.value"
          >{{ dateOption.text }}</mat-option
        >
      </mat-select>
    </mat-form-field>
  </section>
</ng-container>
