import { createSelector } from '@ngrx/store';
import * as reducers from '../reducers';

export const getPhotographersState = createSelector(
  reducers.getAppState,
  (state: reducers.AppState) => state.photographers
);

export const getIsLoadingPhotographers = createSelector(
  getPhotographersState,
  (state) => state.isLoadingPhotographers
);

export const getPhotographers = createSelector(
  getPhotographersState,
  (state) => state.photographers
);

export const getPhotographersAvailable = createSelector(
  getPhotographersState,
  (state) => state.photographersAvailable
);
