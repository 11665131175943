import { BreakpointObserver } from '@angular/cdk/layout';
import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, map, tap } from 'rxjs';
import { AppState, getFeatureFlags, getToken } from 'src/app/+state';
import { FeatureFlags, NavItem } from 'src/app/models';

@Component({
  selector: 'swi-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
})
export class NavigationComponent {
  adminNavItem: NavItem = {
    link: 'admin',
    text: 'Admin',
  };
  bookingNavItem: NavItem = {
    link: 'booking',
    text: 'Book Now',
  };
  loginNavItem: NavItem = {
    link: 'login',
    text: 'Login',
  };
  navItems: NavItem[] = [
    {
      link: '/',
      text: 'Home',
    },
    {
      link: 'about',
      text: 'Meet Our Team',
    },
    {
      link: 'investment',
      text: 'Investment',
    },
    {
      link: 'galleries',
      text: 'Galleries',
    },
    {
      link: 'contact',
      text: 'Contact',
    },
  ];

  featureFlags$ = this.appState.select(getFeatureFlags);
  isMobileNav$ = this.breakpointObserver
    .observe('(max-width: 800px)')
    .pipe(map((bp) => bp.matches));
  token$ = this.appState.select(getToken);

  vm$ = combineLatest([
    this.featureFlags$,
    this.isMobileNav$,
    this.token$,
  ]).pipe(
    tap(([featureFlags, _isMobileNav, token]) =>
      this.setupNavItems(featureFlags, token)
    ),
    map(([_featureFlags, isMobileNav, _token]) => ({ isMobileNav }))
  );

  constructor(
    private appState: Store<AppState>,
    private breakpointObserver: BreakpointObserver
  ) {}

  setupNavItems(featureFlags: FeatureFlags | null, token: string | null): void {
    if (featureFlags?.bookingEnabled) {
      let bookingNavItemIndex = this.navItems.findIndex(
        (ni) => ni.link === this.bookingNavItem.link
      );
      if (bookingNavItemIndex === -1) {
        bookingNavItemIndex = 5;
        this.navItems.splice(bookingNavItemIndex, 0, this.bookingNavItem);
      }
    }

    if (featureFlags?.adminEnabled) {
      if (token) {
        const loginNavItemIndex = this.navItems.findIndex(
          (ni) => ni.link === this.loginNavItem.link
        );
        if (loginNavItemIndex > -1) {
          this.navItems.splice(loginNavItemIndex, 1, this.adminNavItem);
        } else {
          this.navItems.push(this.adminNavItem);
        }
      } else {
        const adminNavItemIndex = this.navItems.findIndex(
          (ni) => ni.link === this.adminNavItem.link
        );
        if (adminNavItemIndex > -1) {
          this.navItems.splice(adminNavItemIndex, 1, this.loginNavItem);
        } else {
          this.navItems.push(this.loginNavItem);
        }
      }
    }
  }
}
