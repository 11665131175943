import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { combineLatest, map, tap } from 'rxjs';
import {
  AppState,
  clearAvailability,
  getAvailabilityEntities,
  getIsLoadingAvailability,
  loadAvailability,
  updateAvailability,
} from 'src/app/+state';
import { DayOfWeek, getDayOfWeekByName } from 'src/app/enums';
import { AvailabilityEntity } from '../../models';

@Component({
  selector: 'swi-manage-availability',
  templateUrl: './manage-availability.component.html',
  styleUrls: ['./manage-availability.component.scss'],
})
export class ManageAvailabilityComponent implements OnDestroy, OnInit {
  availabilityEntityId: string = '';
  formGroup: FormGroup;

  availability$ = this.appState.select(getAvailabilityEntities).pipe(
    map((availabilityEntities) =>
      availabilityEntities?.find((e) => e.userId === this.userId)
    ),
    map((availabilityEntity) => {
      this.availabilityEntityId = availabilityEntity?.id || '';
      this.formGroup.patchValue({
        maxForwardBooking: availabilityEntity?.maxForwardBooking,
      });
      return availabilityEntity?.availability;
    }),
    tap((availability) => {
      if (availability) {
        this.formGroup.patchValue({
          sunday: availability.includes(DayOfWeek.Sunday),
          monday: availability.includes(DayOfWeek.Monday),
          tuesday: availability.includes(DayOfWeek.Tuesday),
          wednesday: availability.includes(DayOfWeek.Wednesday),
          thursday: availability.includes(DayOfWeek.Thursday),
          friday: availability.includes(DayOfWeek.Friday),
          saturday: availability.includes(DayOfWeek.Saturday),
        });
      }
    })
  );
  isLoading$ = this.appState.select(getIsLoadingAvailability);

  vm$ = combineLatest([this.availability$, this.isLoading$]).pipe(
    map(([_availability, _isLoading]) => ({}))
  );

  constructor(
    @Inject(MAT_DIALOG_DATA) public userId: string,
    private appState: Store<AppState>,
    fb: FormBuilder,
    public dialogRef: MatDialogRef<ManageAvailabilityComponent>
  ) {
    this.formGroup = fb.group({
      sunday: false,
      monday: false,
      tuesday: false,
      wednesday: false,
      thursday: false,
      friday: false,
      saturday: false,
      maxForwardBooking: [
        24,
        [
          Validators.required,
          Validators.min(1),
          Validators.max(24),
          Validators.pattern('^[0-9]*$'),
        ],
      ],
    });
  }

  ngOnDestroy(): void {
    this.appState.dispatch(clearAvailability());
  }

  ngOnInit(): void {
    this.appState.dispatch(loadAvailability({ userId: this.userId }));
  }

  save(): void {
    const availability: DayOfWeek[] = [];
    const formValues = this.formGroup.getRawValue();
    Object.keys(formValues).forEach((k) => {
      if (k !== 'maxForwardBooking' && formValues[k]) {
        const dayOfWeek = getDayOfWeekByName(k);
        if (dayOfWeek !== null) {
          availability.push(dayOfWeek);
        }
      }
    });
    const availabilityEntity: AvailabilityEntity = {
      availability,
      id: this.availabilityEntityId,
      maxForwardBooking: formValues.maxForwardBooking,
      userId: this.userId,
    };
    const callback = () => this.dialogRef.close();
    this.appState.dispatch(
      updateAvailability({ availabilityEntity, callback })
    );
  }
}
