import { Component } from '@angular/core';

@Component({
  selector: 'swi-logo-banner',
  templateUrl: './logo-banner.component.html',
  styleUrls: ['./logo-banner.component.scss']
})
export class LogoBannerComponent {

}
