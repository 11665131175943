import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  AvailabilityEntity,
  AvailableDate,
  BasicResponse,
  LoadAvailableDatesRequest,
  UpdateAvailableDateRequest,
} from '../models';

@Injectable({
  providedIn: 'root',
})
export class AvailabilityService {
  availableDatesUrl: string;
  availabilityUrl: string;

  constructor(private http: HttpClient) {
    this.availableDatesUrl = '/AvailableDates';
    this.availabilityUrl = '/Availability';
  }

  getAvailableDates(
    request: LoadAvailableDatesRequest
  ): Observable<AvailableDate[]> {
    let url = this.availableDatesUrl;
    let params = new HttpParams({});
    params = params.append('date', request.date);
    if (request.location) {
      params = params.append('location', request.location);
    }
    if (request.userId) {
      url = `${url}/${request.userId}`;
    }
    return this.http.get<AvailableDate[]>(url, { params });
  }

  getAvailability(userId: string): Observable<AvailabilityEntity> {
    const params = { userId };
    return this.http.get<AvailabilityEntity>(this.availabilityUrl, { params });
  }

  updateAvailableDate(
    request: UpdateAvailableDateRequest
  ): Observable<BasicResponse> {
    return this.http.put<BasicResponse>(this.availableDatesUrl, request);
  }

  updateAvailability(
    availabilityEntity: AvailabilityEntity
  ): Observable<BasicResponse> {
    return this.http.put<BasicResponse>(
      this.availabilityUrl,
      availabilityEntity
    );
  }
}
