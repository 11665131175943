<mat-dialog-content>
  <div class="logo-container">
    <img src="./assets/images/cg-photos-logo.png" />
  </div>
  Christina Griffith Photography is now Saltwater Images! Same great people,
  awesome new look!
  <div class="logo-container">
    <img src="./assets/images/swi-banner-logo.png" />
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <swi-button (onClick)="dialogRef.close()" [isPrimary]="true" [pretty]="true"
    >Continue</swi-button
  >
</mat-dialog-actions>
