import { Action, createReducer, on } from '@ngrx/store';
import * as GoogleCalendarActions from '../actions/google-calendar.actions';

export interface State {
  isFetchConsentPageUrl: boolean;
  consentPageUrl: string | null;
}

const initialState: State = {
  isFetchConsentPageUrl: false,
  consentPageUrl: null,
};

const googleCalendarReducer = createReducer(
  initialState,
  on(GoogleCalendarActions.loadConsentPageUrl, (state) => ({
    ...state,
    isFetchConsentPageUrl: true,
  })),
  on(GoogleCalendarActions.loadConsentPageUrlSuccess, (state, { url }) => ({
    ...state,
    isFetchConsentPageUrl: false,
    consentPageUrl: url,
  })),
  on(GoogleCalendarActions.loadConsentPageUrlFailure, (state) => ({
    ...state,
    isFetchConsentPageUrl: false,
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return googleCalendarReducer(state, action);
}
