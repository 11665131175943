import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { combineLatest, map } from 'rxjs';
import {
  AppState,
  getAppSettings,
  getIsSendingMessage,
  sendMessage,
} from 'src/app/+state';
import { PHONE_PATTERN } from 'src/app/constants';
import { ContactForm } from 'src/app/models';
import { GtmService } from 'src/app/services';

@Component({
  selector: 'swi-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss'],
})
export class ContactFormComponent {
  contactForm: FormGroup;

  appSettings$ = this.appState.select(getAppSettings);
  isSendingMessage$ = this.appState.select(getIsSendingMessage);
  // locations$ = this.appState.select(getAllLocations).pipe(
  //   tap((l) => {
  //     if (l) {
  //       this.populateForm(l);
  //     }
  //   })
  // );

  vm$ = combineLatest([this.appSettings$, this.isSendingMessage$]).pipe(
    map(([appSettings, isSendingMessage]) => ({
      appSettings,
      isSendingMessage,
    }))
  );

  constructor(
    private appState: Store<AppState>,
    fb: FormBuilder,
    private gtmService: GtmService
  ) // private route: ActivatedRoute
  {
    this.contactForm = fb.group({
      dateRange: '',
      email: ['', Validators.email],
      // location: ['', Validators.required],
      name: ['', Validators.required],
      partySize: ['', Validators.required],
      phone: ['', [Validators.required, Validators.pattern(PHONE_PATTERN)]],
      message: ['', Validators.required],
    });
  }

  // populateForm(locations: Location[]): void {
  //   const date = this.route.snapshot.queryParams['date'];
  //   const location = this.route.snapshot.queryParams['location'];
  //   let prettyDate;
  //   if (date) {
  //     prettyDate = new Date(date).toDateString();
  //     this.contactForm.patchValue({ dateRange: prettyDate });
  //   }
  //   let locationFull: Location | undefined;
  //   if (location) {
  //     locationFull = locations.find((l) => l.id === location);
  //     this.contactForm.patchValue({ location });
  //   }
  //   if (date || location) {
  //     this.contactForm.patchValue({
  //       message: `I am interest in booking a photo shoot ${
  //         locationFull ? 'in ' + locationFull.name : ''
  //       } on ${prettyDate}`,
  //     });
  //   }
  // }

  pushGtmEvent(): void {
    const gtmTag = {
      event: 'conversionAchieved',
    };
    this.gtmService.pushTag(gtmTag);
  }

  sendMessage(): void {
    const contactForm: ContactForm = {
      ...this.contactForm.getRawValue(),
    };
    this.contactForm.reset();
    this.appState.dispatch(sendMessage({ contactForm }));
    this.pushGtmEvent();
  }
}
