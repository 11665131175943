import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, map } from 'rxjs';
import {
  AppState,
  getCurrentUser,
  getIsLoadingAvailableDates,
} from 'src/app/+state';

@Component({
  selector: 'swi-my-calendar-tab',
  templateUrl: './my-calendar-tab.component.html',
  styleUrls: ['./my-calendar-tab.component.scss'],
})
export class MyCalendarTabComponent {
  currentUser$ = this.appState.select(getCurrentUser);
  isLoading$ = this.appState.select(getIsLoadingAvailableDates);

  vm$ = combineLatest([this.currentUser$, this.isLoading$]).pipe(
    map(([currentUser, isLoading]) => ({
      currentUser,
      isLoading,
    }))
  );

  constructor(private appState: Store<AppState>) {}
}
