import { Action, createReducer, on } from '@ngrx/store';
import * as SessionActions from '../actions/session.actions';

export interface State {
  isLoggingIn: boolean;
  isLoggingOut: boolean;
  isVerifyingToken: boolean;
  token: string | null;
  tokenInvalid: boolean;
}

const initialState: State = {
  isLoggingIn: false,
  isLoggingOut: false,
  isVerifyingToken: false,
  token: null,
  tokenInvalid: false,
};

const sessionReducer = createReducer(
  initialState,
  on(SessionActions.login, (state) => ({
    ...state,
    isLoggingIn: true,
    token: initialState.token,
    tokenInvalid: initialState.tokenInvalid,
  })),
  on(SessionActions.loginSuccess, (state, { response }) => ({
    ...state,
    isLoggingIn: false,
    token: response.token,
    tokenInvalid: false,
  })),
  on(SessionActions.loginFailure, (state) => ({
    ...state,
    isLoggingIn: false,
    token: initialState.token,
    tokenInvalid: true,
  })),
  on(SessionActions.logout, (state) => ({
    ...state,
    isLoggingOut: true,
    tokenInvalid: true,
  })),
  on(SessionActions.logoutSuccess, SessionActions.logoutFailure, (state) => ({
    ...state,
    isLoggingOut: false,
    token: initialState.token,
  })),
  on(SessionActions.verifyToken, (state) => ({
    ...state,
    isVerifyingToken: true,
    tokenInvalid: initialState.tokenInvalid,
  })),
  on(SessionActions.verifyTokenValid, (state, { response }) => ({
    ...state,
    isVerifyingToken: false,
    token: response.token,
    tokenInvalid: false,
  })),
  on(SessionActions.verifyTokenInvalid, (state) => ({
    ...state,
    isVerifyingToken: false,
    token: initialState.token,
    tokenInvalid: true,
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return sessionReducer(state, action);
}
