import { Component } from '@angular/core';

@Component({
  selector: 'swi-content-with-image2',
  templateUrl: './content-with-image2.component.html',
  styleUrls: ['./content-with-image2.component.scss']
})
export class ContentWithImage2Component {

}
