import { BookingService, SnackBarService } from 'src/app/services';
import * as BookingsActions from 'src/app/+state/actions/bookings.actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, switchMap } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable()
export class BookingsEffects {
  createBooking$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BookingsActions.createBooking),
      switchMap((action) =>
        this.bookingsService.createBooking(action.booking).pipe(
          map((response) => {
            action.callback?.();
            this.snackBarService.openSuccessSnackBar('Booking created.');
            return BookingsActions.createBookingSuccess({ response });
          }),
          catchError((response) => {
            this.snackBarService.openFailureSnackBar(
              response.error?.message || 'Failed to create booking.'
            );
            return of(BookingsActions.createBookingFailure({ response }));
          })
        )
      )
    )
  );

  deleteBooking$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BookingsActions.deleteBooking),
      switchMap((action) =>
        this.bookingsService.deleteBooking(action.id).pipe(
          map((response) => {
            action.callback?.();
            this.snackBarService.openSuccessSnackBar('Booking deleted.');
            return BookingsActions.deleteBookingSuccess({ response });
          }),
          catchError((response) => {
            this.snackBarService.openFailureSnackBar(
              response.error?.message || 'Failed to delete booking.'
            );
            return of(BookingsActions.deleteBookingFailure({ response }));
          })
        )
      )
    )
  );

  loadBooking$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BookingsActions.loadBooking),
      switchMap((action) =>
        this.bookingsService.getBooking(action.bookingId).pipe(
          map((booking) => BookingsActions.loadBookingSuccess({ booking })),
          catchError((response) => {
            this.snackBarService.openFailureSnackBar(
              response.error?.message || 'Failed to load booking.'
            );
            return of(BookingsActions.loadBookingFailure({ response }));
          })
        )
      )
    )
  );

  loadBookings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BookingsActions.loadBookings),
      switchMap((action) =>
        this.bookingsService.getBookings(action.loadBookingsParams).pipe(
          map((bookings) => BookingsActions.loadBookingsSuccess({ bookings })),
          catchError((response) => {
            this.snackBarService.openFailureSnackBar(
              response.error?.message || 'Failed to load bookings.'
            );
            return of(BookingsActions.loadBookingsFailure({ response }));
          })
        )
      )
    )
  );

  updateBooking$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BookingsActions.updateBooking),
      switchMap((action) =>
        this.bookingsService.updateBooking(action.booking).pipe(
          map((response) => {
            action.callback?.();
            this.snackBarService.openSuccessSnackBar('Booking updated.');
            return BookingsActions.updateBookingSuccess({ response });
          }),
          catchError((response) => {
            this.snackBarService.openFailureSnackBar(
              response.error?.message || 'Failed to update booking.'
            );
            return of(BookingsActions.updateBookingFailure({ response }));
          })
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private bookingsService: BookingService,
    private snackBarService: SnackBarService
  ) {}
}
