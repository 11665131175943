export enum UserAvailability {
  Available,
  PendingBooked,
  Booked,
  Off,
  OutOfLocation,
}

export function getUserAvailabilityText(status: UserAvailability): string {
  switch (status) {
    case UserAvailability.Available:
      return 'Available';
    case UserAvailability.PendingBooked:
      return 'Pending Booked';
    case UserAvailability.Booked:
      return 'Booked';
    case UserAvailability.Off:
      return 'Off';
    case UserAvailability.OutOfLocation:
      return 'Out of Location';
  }
}
