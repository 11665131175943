import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'swi-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent {
  @Input() isPrimary = false;
  @Input() isProcessing = false;
  @Input() pretty = false;
  @Input() text = 'Submit';
  @Output() onClick = new EventEmitter();

  _disabled = false;
  @Input() set disabled(value: boolean) {
    this._disabled = value;
  }
  get disabled(): boolean {
    return this._disabled || this.isProcessing;
  }

  onClicked(): void {
    if (!this.disabled) {
      this.onClick.emit();
    }
  }
}
