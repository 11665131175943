import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SimpleUser, SimpleUserAvailability } from '../models';

@Injectable({
  providedIn: 'root',
})
export class PhotographersService {
  photographersUrl: string;

  constructor(private http: HttpClient) {
    this.photographersUrl = '/Photographers';
  }

  getPhotographers(): Observable<SimpleUser[]> {
    return this.http.get<SimpleUser[]>(this.photographersUrl);
  }

  getPhotographersAvailable(
    date: string,
    location: string
  ): Observable<SimpleUserAvailability[]> {
    const params = {
      date,
      location,
    };
    return this.http.get<SimpleUserAvailability[]>(
      `${this.photographersUrl}Available`,
      { params }
    );
  }
}
