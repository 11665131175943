import { PhotographersService, SnackBarService } from 'src/app/services';
import * as PhotographersActions from 'src/app/+state/actions/photographers.actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, switchMap } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable()
export class PhotographersEffects {
  loadPhotographers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PhotographersActions.loadPhotographers),
      switchMap(() =>
        this.photographersService.getPhotographers().pipe(
          map((photographers) =>
            PhotographersActions.loadPhotographersSuccess({ photographers })
          ),
          catchError((response) => {
            this.snackBarService.openFailureSnackBar(
              response.error?.message || 'Failed to load photographers.'
            );
            return of(
              PhotographersActions.loadPhotographersFailure({ response })
            );
          })
        )
      )
    )
  );

  loadPhotographersAvailable$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PhotographersActions.loadPhotographersAvailable),
      switchMap((action) =>
        this.photographersService
          .getPhotographersAvailable(action.date, action.location)
          .pipe(
            map((photographers) =>
              PhotographersActions.loadPhotographersAvailableSuccess({
                photographers,
              })
            ),
            catchError((response) => {
              this.snackBarService.openFailureSnackBar(
                response.error?.message || 'Failed to load photographers.'
              );
              return of(
                PhotographersActions.loadPhotographersAvailableFailure({
                  response,
                })
              );
            })
          )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private photographersService: PhotographersService,
    private snackBarService: SnackBarService
  ) {}
}
