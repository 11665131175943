import { Action, createReducer, on } from '@ngrx/store';
import * as GalleryActions from '../actions/gallery.actions';

export interface State {
  overlayOpen: boolean;
}

const initialState: State = {
  overlayOpen: false,
};

const galleryReducer = createReducer(
  initialState,
  on(GalleryActions.closeOverlay, (state) => ({
    ...state,
    overlayOpen: false,
  })),
  on(GalleryActions.openOverlay, (state) => ({
    ...state,
    overlayOpen: true,
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return galleryReducer(state, action);
}
