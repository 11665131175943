import { Action, createReducer, on } from '@ngrx/store';
import * as AvailabilityActions from '../actions/availability.actions';
import {
  AvailabilityEntity,
  AvailableDate,
  LoadAvailableDatesRequest,
} from 'src/app/models';

export interface State {
  availableDates: AvailableDate[];
  availabilityEntities: AvailabilityEntity[];
  isLoadingAvailableDates: boolean;
  isLoadingAvailability: boolean;
  isUpdatingAvailability: boolean;
  loadAvailableDatesRequest: LoadAvailableDatesRequest | null;
  loadedDate: string | null;
}

const initialState: State = {
  availableDates: [],
  availabilityEntities: [],
  isLoadingAvailableDates: false,
  isLoadingAvailability: false,
  isUpdatingAvailability: false,
  loadedDate: null,
  loadAvailableDatesRequest: null,
};

const availabilityReducer = createReducer(
  initialState,
  on(AvailabilityActions.clearAvailability, () => initialState),
  on(AvailabilityActions.loadAvailableDates, (state, { request }) => ({
    ...state,
    isLoadingAvailableDates: true,
    loadAvailableDatesRequest: request,
  })),
  on(
    AvailabilityActions.loadAvailableDatesSuccess,
    (state, { availableDates, loadedDate }) => ({
      ...state,
      availableDates,
      isLoadingAvailableDates: false,
      loadedDate,
    })
  ),
  on(AvailabilityActions.loadAvailableDatesFailure, (state) => ({
    ...state,
    isLoadingAvailableDates: false,
  })),
  on(AvailabilityActions.loadAvailability, (state) => ({
    ...state,
    availability: null,
    isLoadingAvailability: true,
  })),
  on(
    AvailabilityActions.loadAvailabilitySuccess,
    (state, { availabilityEntity }) => {
      const availabilityEntities = state.availabilityEntities.filter(
        (e) => e.userId !== availabilityEntity.userId
      );
      availabilityEntities.push(availabilityEntity);
      return {
        ...state,
        availabilityEntities,
      };
    }
  ),
  on(AvailabilityActions.loadAvailabilityFailure, (state) => ({
    ...state,
    isLoadingAvailability: false,
  })),
  on(AvailabilityActions.updateAvailability, (state) => ({
    ...state,
    isUpdatingAvailability: true,
  })),
  on(
    AvailabilityActions.updateAvailabilitySuccess,
    AvailabilityActions.updateAvailabilityFailure,
    (state) => ({
      ...state,
      isUpdatingAvailability: false,
    })
  )
);

export function reducer(state: State | undefined, action: Action) {
  return availabilityReducer(state, action);
}
