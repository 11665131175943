import { createSelector } from '@ngrx/store';
import * as reducers from '../reducers';

export const getGoogleCalendarState = createSelector(
  reducers.getAppState,
  (state: reducers.AppState) => state.googleCalendar
);

export const getIsFetchingConsentPageUrl = createSelector(
  getGoogleCalendarState,
  (state) => state.isFetchConsentPageUrl
);

export const getConsentPageUrl = createSelector(
  getGoogleCalendarState,
  (state) => state.consentPageUrl
);
