import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { combineLatest, map, tap } from 'rxjs';
import {
  AppState,
  deleteBooking,
  getBookings,
  getCurrentUserPermissions,
  getIsLoadingBookings,
  getIsRequestingInvoice,
  getLoadBookingsParams,
  getPhotographers,
  loadBookings,
  sendInvoice,
} from 'src/app/+state';
import { Booking, LoadBookingsParams, SimpleUser } from 'src/app/models';
import { ConfirmationDialogData } from '../confirmation-dialog/confirmation-dialog-data.model';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { getFullName } from 'src/app/utils';
import { getBookingStatusText } from 'src/app/enums';
import { Router } from '@angular/router';

@Component({
  selector: 'swi-bookings-list',
  templateUrl: './bookings-list.component.html',
  styleUrls: ['./bookings-list.component.scss'],
})
export class BookingsListComponent {
  displayedColumns = [
    'date',
    'status',
    'name',
    'phone',
    'email',
    'photographers',
    'actions',
  ];
  getBookingStatusText = getBookingStatusText;
  loadBookingsParams: LoadBookingsParams | undefined;
  photographers: SimpleUser[] = [];

  bookings$ = this.appState.select(getBookings);
  isLoadingBookings$ = this.appState.select(getIsLoadingBookings);
  isRequestingInvoice$ = this.appState.select(getIsRequestingInvoice);
  loadBookingsParams$ = this.appState
    .select(getLoadBookingsParams)
    .pipe(tap((params) => (this.loadBookingsParams = params)));
  permissions$ = this.appState.select(getCurrentUserPermissions);
  photographers$ = this.appState.select(getPhotographers).pipe(
    tap((p) => {
      if (p?.length) {
        this.photographers = p;
      }
    })
  );

  vm$ = combineLatest([
    this.bookings$,
    this.isLoadingBookings$,
    this.isRequestingInvoice$,
    this.permissions$,
    this.photographers$,
  ]).pipe(
    map(
      ([
        bookings,
        isLoading,
        isRequestingInvoice,
        permissions,
        photographers,
      ]) => ({
        bookings,
        isLoading,
        isRequestingInvoice,
        permissions,
        photographers,
      })
    )
  );

  constructor(
    private appState: Store<AppState>,
    private dialog: MatDialog,
    private router: Router
  ) {}

  deleteBooking(booking: Booking): void {
    const data: ConfirmationDialogData = {
      buttonText: 'Delete',
      message: `Are you sure you want to delete the booking for ${booking.name}?`,
    };
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, { data });

    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        const callback = () => this.loadBookings();
        this.appState.dispatch(
          deleteBooking({ id: booking.id as string, callback })
        );
      }
    });
  }

  editBooking(bookingId: string): void {
    this.router.navigate(['admin', 'bookings', bookingId]);
  }

  getPhotographerNames(ids: string[]): string {
    if (!ids?.length || !this.photographers?.length) {
      return '';
    }
    return ids
      .map((id) => this.photographers.find((p) => p.id === id))
      .map((p) => getFullName(p))
      .join(', ');
  }

  loadBookings(): void {
    this.appState.dispatch(
      loadBookings({ loadBookingsParams: this.loadBookingsParams })
    );
  }

  sendInvoice(bookingId: string): void {
    const callback = () => this.loadBookings();
    this.appState.dispatch(sendInvoice({ bookingId, callback }));
  }
}
