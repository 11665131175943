import { createAction, props } from '@ngrx/store';
import { FeatureFlags } from 'src/app/models';

export const loadFeatureFlags = createAction(
  '[FeatureFlags] Load Feature Flags'
);

export const loadFeatureFlagsSuccess = createAction(
  '[FeatureFlags] Load Feature Flags Success',
  props<{ featureFlags: FeatureFlags }>()
);

export const loadFeatureFlagsFailure = createAction(
  '[FeatureFlags] Load Feature Flags Failure'
);
