import { createAction, props } from '@ngrx/store';
import { BasicResponse, Booking, LoadBookingsParams } from 'src/app/models';

export const clearBookings = createAction('[Bookings] Clear Bookings');

export const createBooking = createAction(
  '[Bookings] Create Booking',
  props<{ booking: Booking; callback?: () => void }>()
);

export const createBookingSuccess = createAction(
  '[Bookings] Create Booking Success',
  props<{ response: BasicResponse }>()
);

export const createBookingFailure = createAction(
  '[Bookings] Create Booking Failure',
  props<{ response: BasicResponse }>()
);

export const deleteBooking = createAction(
  '[Bookings] Delete Booking',
  props<{ id: string; callback?: () => void }>()
);

export const deleteBookingSuccess = createAction(
  '[Bookings] Delete Booking Success',
  props<{ response: BasicResponse }>()
);

export const deleteBookingFailure = createAction(
  '[Bookings] Delete Booking Failure',
  props<{ response: BasicResponse }>()
);

export const loadBooking = createAction(
  '[Bookings] Load Booking',
  props<{ bookingId: string }>()
);

export const loadBookingSuccess = createAction(
  '[Bookings] Load Booking Success',
  props<{ booking: Booking }>()
);

export const loadBookingFailure = createAction(
  '[Bookings] Load Booking Failure',
  props<{ response: BasicResponse }>()
);

export const loadBookings = createAction(
  '[Bookings] Load Bookings',
  props<{ loadBookingsParams?: LoadBookingsParams }>()
);

export const loadBookingsSuccess = createAction(
  '[Bookings] Load Bookings Success',
  props<{ bookings: Booking[] }>()
);

export const loadBookingsFailure = createAction(
  '[Bookings] Load Bookings Failure',
  props<{ response: BasicResponse }>()
);

export const updateBooking = createAction(
  '[Bookings] Update Booking',
  props<{ booking: Booking; callback?: () => void }>()
);

export const updateBookingSuccess = createAction(
  '[Bookings] Update Booking Success',
  props<{ response: BasicResponse }>()
);

export const updateBookingFailure = createAction(
  '[Bookings] Update Booking Failure',
  props<{ response: BasicResponse }>()
);
