import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { AppState, closeOverlay, openOverlay } from 'src/app/+state';

@Component({
  selector: 'swi-galleries-page',
  templateUrl: './galleries-page.component.html',
  styleUrls: ['./galleries-page.component.scss'],
})
export class GalleriesPageComponent implements OnDestroy, OnInit {
  bpSub$: Subscription | undefined;
  images: string[] = [];
  isSmallScreen = false;
  focusedImage = this.images[0];
  overlayOpen = false;

  constructor(
    private appState: Store<AppState>,
    private breakpointObserver: BreakpointObserver
  ) {
    this.bpSub$ = this.breakpointObserver
      .observe('(max-width: 1300px)')
      .subscribe((bp) => {
        this.isSmallScreen = bp.matches;
        if (this.isSmallScreen) {
          this.closeGalleryOverlay();
        }
      });
  }

  ngOnInit(): void {
    for (let i = 1; i <= 40; i++) {
      this.images.push(`./assets/images/family-photography-gallery-${i}.jpg`);
    }
  }

  ngOnDestroy(): void {
    this.bpSub$?.unsubscribe();
  }

  closeGalleryOverlay(): void {
    document.body.classList.remove('ovh');
    this.overlayOpen = false;
    this.appState.dispatch(closeOverlay());
  }

  nextClicked(): void {
    const currentIndex = this.images.findIndex(
      (img) => img === this.focusedImage
    );
    let newIndex = currentIndex + 1;
    if (newIndex >= this.images.length) {
      newIndex = 0;
    }
    this.focusedImage = this.images[newIndex];
  }

  openGalleryAtIndex(index: number): void {
    if (this.isSmallScreen) return;
    this.focusedImage = this.images[index];
    this.overlayOpen = true;
    document.body.classList.add('ovh');
    this.appState.dispatch(openOverlay());
  }

  prevClicked(): void {
    const currentIndex = this.images.findIndex(
      (img) => img === this.focusedImage
    );
    let newIndex = currentIndex - 1;
    if (newIndex < 0) {
      newIndex = this.images.length - 1;
    }
    this.focusedImage = this.images[newIndex];
  }
}
