<div class="form-container">
  <form [formGroup]="formGroup">
    <mat-form-field>
      <mat-label>Current Password</mat-label>
      <input matInput formControlName="password" type="password" />
      <mat-error *ngIf="formGroup.get('password')?.hasError('required')"
        >Password is required.</mat-error
      >
    </mat-form-field>
    <mat-form-field>
      <mat-label>New Password</mat-label>
      <input matInput formControlName="newPassword" type="password" />
      <mat-error *ngIf="formGroup.get('newPassword')?.hasError('required')"
        >New password is required.</mat-error
      >
      <mat-error *ngIf="formGroup.get('newPassword')?.hasError('pattern')"
        >Minimum eight characters, at least one letter, one number and one
        special characte</mat-error
      >
    </mat-form-field>
    <mat-form-field>
      <mat-label>Confirm Password</mat-label>
      <input matInput formControlName="confirmPassword" type="password" />
      <mat-error *ngIf="!passwordsMatch()">Password must match</mat-error>
    </mat-form-field>
    <div class="buttons-container">
      <swi-button
        (onClick)="submit()"
        [disabled]="!formIsValid()"
        [isProcessing]="(isChangingPassword$ | async) === true"
        >Submit</swi-button
      >
      <swi-button (onClick)="closeDialog()">Cancel</swi-button>
    </div>
  </form>
</div>
