import { Action, createReducer, on } from '@ngrx/store';
import * as BookingsActions from '../actions/bookings.actions';
import { Booking, LoadBookingsParams } from 'src/app/models';

export interface State {
  booking: Booking | null;
  bookings: Booking[];
  isLoadingBooking: boolean;
  isLoadingBookings: boolean;
  isSavingBooking: boolean;
  loadBookingsParams: LoadBookingsParams | undefined;
}

const initialState: State = {
  booking: null,
  bookings: [],
  isLoadingBooking: false,
  isLoadingBookings: false,
  isSavingBooking: false,
  loadBookingsParams: {},
};

const bookingsReducer = createReducer(
  initialState,
  on(BookingsActions.clearBookings, () => initialState),
  on(
    BookingsActions.createBooking,
    BookingsActions.deleteBooking,
    BookingsActions.updateBooking,
    (state) => ({
      ...state,
      isSavingBooking: true,
    })
  ),
  on(
    BookingsActions.createBookingSuccess,
    BookingsActions.createBookingFailure,
    BookingsActions.deleteBookingSuccess,
    BookingsActions.deleteBookingFailure,
    BookingsActions.updateBookingSuccess,
    BookingsActions.updateBookingFailure,
    (state) => ({
      ...state,
      isSavingBooking: false,
    })
  ),
  on(BookingsActions.loadBooking, (state) => ({
    ...state,
    isLoadingBooking: true,
  })),
  on(BookingsActions.loadBookingSuccess, (state, { booking }) => ({
    ...state,
    booking,
    isLoadingBooking: false,
  })),
  on(BookingsActions.loadBookingFailure, (state) => ({
    ...state,
    booking: null,
    isLoadingBooking: false,
  })),
  on(BookingsActions.loadBookings, (state, { loadBookingsParams }) => ({
    ...state,
    isLoadingBookings: true,
    loadBookingsParams,
  })),
  on(BookingsActions.loadBookingsSuccess, (state, { bookings }) => ({
    ...state,
    bookings,
    isLoadingBookings: false,
  })),
  on(BookingsActions.loadBookingsFailure, (state) => ({
    ...state,
    bookings: [],
    isLoadingBookings: false,
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return bookingsReducer(state, action);
}
